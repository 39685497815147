import React, { useState } from 'react';
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Select,
  VStack,
  Icon,
  Text,
} from '@chakra-ui/react';
import { TbPackageExport } from "react-icons/tb";
import { BiGitRepoForked } from 'react-icons/bi';
import { defaultPipelines } from './defaultPipelines';

export const LoadPipelinePopover = ({ 
  isLoading, 
  onLoad, 
  isDisabled,
  selectedPipeline,
  onPipelineSelect,
  onClose 
}) => {
  const [localSelectedPipeline, setLocalSelectedPipeline] = useState(
    Array.isArray(selectedPipeline) ? 'default' : (selectedPipeline || 'default')
  );
  
  const handlePipelineChange = (e) => {
    const value = e.target.value;
    setLocalSelectedPipeline(value);
  };

  const handlePipelineLoad = () => {
    if (!localSelectedPipeline || !defaultPipelines[localSelectedPipeline]) {
      console.warn("Invalid pipeline selection:", localSelectedPipeline);
      console.log("Available pipelines:", Object.keys(defaultPipelines));
      return;
    }

    try {
      const pipelineData = defaultPipelines[localSelectedPipeline].pipeline;
      
      if (!pipelineData) {
        throw new Error("Pipeline data is undefined");
      }

      console.log("Loading pipeline:", {
        type: localSelectedPipeline,
        name: defaultPipelines[localSelectedPipeline].name,
        nodes: Object.keys(pipelineData).length
      });
      
      // Pass both pipelineData and localSelectedPipeline
      onLoad?.(pipelineData, localSelectedPipeline);
      onPipelineSelect?.(localSelectedPipeline);
      
    } catch (error) {
      console.error("Error loading pipeline:", error);
    }
  };

  return (
    <Popover placement="bottom-start" onClose={onClose}>
      <PopoverTrigger>
        <Button
          size="sm"
          variant="ghost"
          leftIcon={<Icon as={localSelectedPipeline ? BiGitRepoForked : TbPackageExport} />}
          isDisabled={isDisabled}
        >
          Load Pipeline
        </Button>
      </PopoverTrigger>
      <PopoverContent bg="gray.800" borderColor="gray.600">
        <PopoverArrow bg="gray.800" />
        <PopoverCloseButton />
        <PopoverHeader borderBottomWidth="1px" borderColor="gray.600">Load Pipeline</PopoverHeader>
        <PopoverBody>
          <VStack spacing={4} align="stretch">
            <Text fontSize="sm">Select a pipeline template to load:</Text>
            <Select
              value={localSelectedPipeline}
              onChange={handlePipelineChange}
              variant="filled"
              size="sm"
              bg="gray.700"
              _hover={{ bg: "gray.600" }}
            >
              {Object.entries(defaultPipelines).map(([key, pipeline]) => (
                <option key={key} value={key}>
                  {pipeline.name}
                </option>
              ))}
            </Select>
          </VStack>
        </PopoverBody>
        <PopoverFooter 
          borderTopWidth="1px" 
          borderColor="gray.600"
          display="flex" 
          justifyContent="flex-end"
        >
          <Button
            size="sm"
            colorScheme="blue"
            onClick={handlePipelineLoad}
            isLoading={isLoading}
          >
            Load
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};