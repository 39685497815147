export const defaultPipelines = {
  default: {
    name: "Text to Image",
    pipeline: {
      CheckpointLoaderSimple: {
        class_type: "CheckpointLoaderSimple",
        inputs: {
          ckpt_name: "4610115bb0c89560703c892c59ac2742fa821e60ef5871b33493ba544683abd7",  // Flux.1 D
        }
      },
      2: {
        class_type: "CLIPTextEncode",
        inputs: {
          text: "a beautiful sunset over mountains",
          clip: ["CheckpointLoaderSimple", 1]
        }
      },
      3: {
        class_type: "CLIPTextEncode",
        inputs: {
          text: "bad art, blurry, distorted",
          clip: ["CheckpointLoaderSimple", 1]
        }
      },
      4: {
        class_type: "EmptyLatentImage",
        inputs: {
          batch_size: 1,
          height: 768,
          width: 512
        }
      },
      5: {
        class_type: "KSampler",
        inputs: {
          seed: 1234,
          steps: 20,
          cfg: 7,
          sampler_name: "euler",
          scheduler: "normal",
          denoise: 1,
          model: ["CheckpointLoaderSimple", 0],
          positive: ["2", 0],
          negative: ["3", 0],
          latent_image: ["4", 0]
        }
      },
      6: {
        class_type: "VAEDecode",
        inputs: {
          samples: ["5", 0],
          vae: ["CheckpointLoaderSimple", 2]
        }
      },
      save: {
        class_type: "SaveImage",
        inputs: {
          filename_prefix: "ComfyUI",
          images: ["6", 0]
        }
      }
    },
    assets: {}
  },
  img2img: {
    name: "Image to Image",
    pipeline: {
      1: {
        class_type: "CheckpointLoaderSimple",
        inputs: {
          ckpt_name: "4610115bb0c89560703c892c59ac2742fa821e60ef5871b33493ba544683abd7",  // Flux.1 D
        }
      },
      2: {
        class_type: "LoadImage",
        inputs: {
          image: "placeholder.png"
        }
      },
      3: {
        class_type: "VAEEncode",
        inputs: {
          pixels: ["2", 0],
          vae: ["1", 2]
        }
      },
      4: {
        class_type: "CLIPTextEncode",
        inputs: {
          text: "a beautiful sunset over mountains",
          clip: ["1", 1]
        }
      },
      5: {
        class_type: "CLIPTextEncode",
        inputs: {
          text: "bad art, blurry, distorted",
          clip: ["1", 1]
        }
      },
      6: {
        class_type: "KSampler",
        inputs: {
          seed: 1234,
          steps: 20,
          cfg: 7,
          sampler_name: "euler",
          scheduler: "normal",
          denoise: 0.75,
          model: ["1", 0],
          positive: ["4", 0],
          negative: ["5", 0],
          latent_image: ["3", 0]
        }
      },
      7: {
        class_type: "VAEDecode",
        inputs: {
          samples: ["6", 0],
          vae: ["1", 2]
        }
      },
      8: {
        class_type: "SaveImage",
        inputs: {
          filename_prefix: "ComfyUI",
          images: ["7", 0]
        }
      }
    },
    assets: {}
  }
};
