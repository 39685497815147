import { useState, useEffect } from 'react'
import { BiLayerPlus } from 'react-icons/bi'
import { 
    Flex, Spacer, Badge, Input, List, Button, 
    Popover, PopoverTrigger, PopoverContent, PopoverArrow, 
    PopoverBody, PopoverHeader, Tooltip, ListItem,
    Box
} from '@chakra-ui/react'

export function NodeDropdown({
    obj,
    onClick = (v) => { console.log(`No onClick handler for NodeDropdown.  Value: ${v}`) },
    asPopover = false
}) {
    const [filteredNodes, setFilteredNodes] = useState([])
    const [filter, setFilter] = useState("")

    useEffect(() => {
        if (!obj) return;
        setFilteredNodes(Object.keys(obj).filter((value) => {
            if (value.toLowerCase().indexOf(filter.toLowerCase()) > -1) return true
            return false
        }))
    }, [filter, obj])

    const NodeList = () => (
        <>
            <Box p={2}>
                {Object.keys(obj || {}).length > 0 && 
                    <Input 
                        placeholder='Filter nodes...' 
                        value={filter} 
                        onChange={e => setFilter(e.currentTarget.value)}
                        mb={2}
                    />
                }
            </Box>
            <List maxHeight="400px" overflowY="auto">
                {filteredNodes.map((key) => (
                    <ListItem 
                        key={key} 
                        value={key} 
                        p={2} 
                        cursor="pointer" 
                        _hover={{ bg: "gray.700" }}
                        onClick={() => onClick(key)}
                    >
                        <Flex>
                            {key}
                        </Flex>
                    </ListItem>
                ))}
            </List>
        </>
    )

    if (!asPopover) {
        return <NodeList />
    }

    return (
        <Popover>
            <PopoverTrigger>
                <Box display="inline-block">
                    <Tooltip hasArrow openDelay={250} label="Add Node...">
                        <Button 
                            leftIcon={<BiLayerPlus />}
                            size="sm"
                            variant="ghost"
                        > Add Node 
                        </Button>
                    </Tooltip>
                </Box>
            </PopoverTrigger>
            <PopoverContent bg="gray.800" borderColor="gray.700">
                <PopoverHeader fontWeight='semibold' color="gray.100">Add Node</PopoverHeader>
                <PopoverArrow bg="gray.800" />
                <PopoverBody p={0}>
                    <NodeList />
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}