import { 
  Flex, Box, FormLabel, Input, NumberInput, NumberInputField, 
  NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
  Switch, Select, Textarea, useDisclosure, Progress, Wrap, IconButton,
  Tooltip, useToast, ButtonGroup, Button, Modal, ModalOverlay, ModalContent, 
  ModalHeader, ModalCloseButton, ModalBody, ModalFooter, HStack, Image as ChakraImage, Text,
  Popover, PopoverBody, PopoverTrigger, PopoverArrow, PopoverContent, PopoverHeader, Badge
} from '@chakra-ui/react';
import { useState, useEffect, useCallback } from 'react';
import { getEnvVariable } from 'utils/env';
import { BiLayerPlus, BiShuffle } from 'react-icons/bi';
import { FaRegKeyboard } from 'react-icons/fa';
import { useAuth } from 'contexts/AuthContext';
import { AiOutlineCloudUpload } from "react-icons/ai"
import { TfiGallery } from "react-icons/tfi";
import { PiUpload, PiCamera } from "react-icons/pi"
import { ModalModelBrowser } from 'components/Models/ModalModelBrowser';
import ModelButton from 'components/Models/ModelButton';
import { calculateBlobSHA256, calculateSHA256 } from 'utils/pieceUtils';
import { ImageOptions } from './loadImageOptions';
import  FileDropZone  from 'components/shared/FileDropZone';
import { ModalUploadsBrowser } from 'components/shared/ModalUploadsBrowser';
import WebcamCapture from 'components/shared/WebcamCapture'

// Base field components for different types
export const BaseFields = {
  STRING: ({ label, value, onChange, fieldName }) => (
    <Flex>
      <FormLabel>{label}</FormLabel>
      <Input
        value={value ?? ''}
        onChange={(e) => onChange(e.target.value)}
      />
    </Flex>
  ),

  INT: ({ label, value, onChange, min, max, step = 1, fieldName }) => (
    <Box>
      <FormLabel>{label}</FormLabel>
      <NumberInput
        value={value ?? 0}
        min={min}
        max={max}
        step={step}
        precision={0}
        onChange={(valueString) => onChange(parseInt(valueString))}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Box>
  ),

  FLOAT: ({ label, value, onChange, min, max, step = 0.1, fieldName }) => (
    <Box>
      <FormLabel>{label}</FormLabel>
      <NumberInput
        value={value ?? 0.0}
        min={min}
        max={max}
        step={step}
        precision={2}
        onChange={(valueString) => onChange(parseFloat(valueString))}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Box>
  ),

  BOOLEAN: ({ label, value, onChange, fieldName }) => (
    <Box alignItems="center">
      <FormLabel mb="0">{label}</FormLabel>
      <Switch
        isChecked={value ?? false}
        onChange={(e) => onChange(e.target.checked)}
      />
    </Box>
  ),

  SELECT: ({ label, value, onChange, fieldName, options = [] }) => {
    const [modelNames, setModelNames] = useState({});
    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url);
    const needsHashValidation = fieldName?.toLowerCase().endsWith('_name');

    useEffect(() => {
      if (!needsHashValidation || !options.length) return;

      // Clear old model names when options change
      setModelNames({});

      // Fetch model names for all options
      options.forEach(opt => {
        const hashOnly = opt.replace('.safetensors', '');
        // Only fetch if it looks like a hash (64 hex chars)
        if (hashOnly.match(/^[a-f0-9]{64}$/)) {
          fetch(`${REACT_APP_api_url}/v3/modelhash/${hashOnly}`)
            .then(response => {
              if (!response.ok) throw new Error('Model not found');
              return response.json();
            })
            .then(data => {
              setModelNames(prev => ({
                ...prev,
                [opt]: data.name || data.model_name || opt
              }));
            })
            .catch(err => {
              console.error('Error fetching model name:', err);
              // Keep the shortened hash version on error
              setModelNames(prev => ({
                ...prev,
                [opt]: `${hashOnly.substring(0, 8)}...${hashOnly.substring(56)}`
              }));
            });
        }
      });
    }, [options, needsHashValidation, REACT_APP_api_url]);

    const getDisplayName = (value) => {
      if (!value) return '';
      if (modelNames[value]) return modelNames[value];
      
      // Remove .safetensors extension and check if it's a hash
      const baseName = value.replace('.safetensors', '');
      if (baseName.match(/^[a-f0-9]{64}$/)) {
        return `${baseName.substring(0, 8)}...${baseName.substring(56)}`;
      }
      return value;
    };

    const handleChange = async (selectedValue) => {
      if (!needsHashValidation) {
        onChange(selectedValue);
        return;
      }

      const hashRegex = /^[a-f0-9]{64}$/;
      const baseName = selectedValue.replace('.safetensors', '');

      try {
        if (hashRegex.test(baseName)) {
          onChange(`${baseName}.safetensors`);
          return;
        }

        const hash = await calculateSHA256(baseName);
        if (!hashRegex.test(hash)) {
          console.warn('Generated invalid hash:', hash);
          onChange(selectedValue);
          return;
        }

        onChange(`${hash}.safetensors`);
      } catch (err) {
        console.error('Hash calculation failed:', err);
        onChange(selectedValue);
      }
    };

    return (
      <Flex direction="column" gap={1} mb={2}>
        <FormLabel margin={0} fontSize="sm">{label}</FormLabel>
        <Select
          value={value ?? ''}
          onChange={(e) => handleChange(e.target.value)}
          bg="gray.800"
          borderColor="gray.600"
          _hover={{ borderColor: "gray.500" }}
          size="sm"
        >
          {options.map((opt, i) => (
            <option key={i} value={opt}>
              {getDisplayName(opt)}
            </option>
          ))}
        </Select>
      </Flex>
    );
  },

  CLIP: ({ label, value, onChange, fieldName, options = [] }) => {
    const [modelNames, setModelNames] = useState({});
    const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url);
  
    useEffect(() => {
      if (!options.length) return;
      setModelNames({});
  
      options.forEach(opt => {
        const hashOnly = opt.replace('.safetensors', '');
        if (hashOnly.match(/^[a-f0-9]{64}$/)) {
          fetch(`${REACT_APP_api_url}/v3/modelhash/${hashOnly}`)
            .then(response => {
              if (!response.ok) throw new Error('Model not found');
              return response.json();
            })
            .then(data => {
              setModelNames(prev => ({
                ...prev,
                [opt]: data.name || data.model_name || opt
              }));
            })
            .catch(err => {
              console.error('Error fetching model name:', err);
              setModelNames(prev => ({
                ...prev,
                [opt]: opt
              }));
            });
        }
      });
    }, [options, REACT_APP_api_url]);
  
    const getDisplayName = (value) => {
      if (!value) return '';
      if (modelNames[value]) return modelNames[value];
      return value;
    };
  
    return (
      <Flex direction="column" gap={1} mb={2}>
        <FormLabel margin={0} fontSize="sm">{label}</FormLabel>
        <Select
          value={value ?? ''}
          onChange={(e) => onChange(e.target.value)}
          bg="gray.800"
          borderColor="gray.600"
          _hover={{ borderColor: "gray.500" }}
          size="sm"
        >
          {options.map((opt, i) => (
            <option key={i} value={opt}>
              {getDisplayName(opt)}
            </option>
          ))}
        </Select>
      </Flex>
    );
  },

  MODEL: ({ label, value, onChange, fieldName, options = [] }) => (
    <Flex direction="column" gap={1} mb={2}>
      <FormLabel margin={0} fontSize="sm">{label}</FormLabel>
      <Select
        value={value ?? ''}
        onChange={(e) => onChange(e.target.value)}
        bg="gray.800"
        borderColor="gray.600"
        _hover={{ borderColor: "gray.500" }}
        size="sm"
      >
        {options.map((opt, i) => (
          <option key={i} value={opt}>
            {opt}
          </option>
        ))}
      </Select>
    </Flex>
  ),


  IMAGE: ({ label, value, onChange, hideImage }) => {
    const [error, setError] = useState('')
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 })
    const [blobContent, setBlobContent] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const { isOpen: isUploadsOpen, onClose: onUploadsClose, onOpen: onUploadsOpen } = useDisclosure()
    const [url, setUrl] = useState("")

    const updateValue = useCallback(v => { onChange(v) }, [onChange])

    const verifyThenSetContent = useCallback(blob => {
      const img = document.createElement('img');
      img.crossOrigin = "Anonymous";
      img.src = URL.createObjectURL(blob);
      img.onerror = (e) => {
        console.log("Error loading image", e);
        setError(`Error loading image. Please check the URL or file.`);
        setIsLoading(false);
      };

      img.onload = () => {
        const processImage = async (blob) => {
          let h = await calculateBlobSHA256(blob);
          setError("");
          setBlobContent(URL.createObjectURL(blob));
          if (h !== value?.hash || blob !== value?.content) {
            updateValue({ hash: h, content: blob });
          }
          setIsLoading(false);
        }
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        imageSize.width = img.width
        imageSize.height = img.height
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
        canvas.toBlob(processImage, 'image/png');
      };
    }, [updateValue, value]);

    useEffect(() => {
      if (value?.content && value.content instanceof Blob) {
        setBlobContent(URL.createObjectURL(value.content))
      } else if (value?.hash) {
        // If we have a hash but no content, try to load from uploads
        setIsLoading(true);
        const REACT_APP_images_url = getEnvVariable("REACT_APP_images_url", process.env.REACT_APP_images_url);
        fetch(`${REACT_APP_images_url}/uploads/${value.hash}`)
          .then(response => {
            if (!response.ok) throw new Error('Failed to fetch image');
            return response.blob();
          })
          .then(blob => {
            verifyThenSetContent(blob);
          })
          .catch(err => {
            console.error('Error loading stored image:', err);
            setError(`Error loading stored image: ${err.message}`);
            setIsLoading(false);
          });
      }
    }, [value?.content, value?.hash, verifyThenSetContent]);

    const urlChangeHandler = (url) => {
      setUrl(url)
      setIsLoading(true)
      // Load image URL to get hash
      // Create DOM element to load content
      const img = new Image()
      img.crossOrigin = "Anonymous"
      img.src = url
      img.onerror = (e) => {
          setError(`Please check if the URL is correct and points directly to an image file.
          If the URL is correct, the image may not be accessible due to CORS restrictions.  
          Check the browser console for more information.
          `)
          setIsLoading(false)
      }

      img.onload = async () => {
          setError("")
          // Create canvas to draw image
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          imageSize.width = img.width
          imageSize.height = img.height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)
          // Get image data from canvas
          canvas.toBlob((blob) => {
              verifyThenSetContent(blob)
              setIsLoading(false)
          }, 'image/png')
      }
    }

    return (
      <Flex direction="column" gap={1} mb={2}>
        <FormLabel margin={0} fontSize="sm">{label}</FormLabel>
        <FileDropZone onDrop={blob => verifyThenSetContent(blob)} />
        <HStack spacing={2}>
          {/* User Uploads */}
          <Box>
            <Tooltip hasArrow openDelay={250} label="Select from Uploads...">
                <IconButton icon={<TfiGallery />} colorScheme="blue" variant={'ghost'} onClick={e=>{
                    onUploadsOpen()
                }}/>
            </Tooltip>
          </Box>
          {/* Upload from URL */}
          <Box>
            <Popover>
                <PopoverTrigger><span>
                    <Tooltip hasArrow openDelay={250} label="Upload from URL...">
                        <IconButton icon={<AiOutlineCloudUpload />} colorScheme="blue" variant={'ghost'} />
                    </Tooltip>
                </span></PopoverTrigger>
                <PopoverContent>
                    <PopoverHeader fontWeight="semibold">Upload from URL</PopoverHeader>
                    <PopoverArrow />
                    <PopoverBody maxHeight={'15rem'} overflowY={'auto'}>
                        <Tooltip hasArrow label="Provide a valid direct URL to a JPG or PNG that you want to use an image" openDelay={250}>
                            <FormLabel htmlFor="url">Image URL</FormLabel>
                        </Tooltip>
                        <Input id="url" placeholder="URL here" value={url} onChange={e => urlChangeHandler(e.target.value)} />
                    </PopoverBody>
                </PopoverContent>
            </Popover>
          </Box>
          {/* Camera Upload */}
          <Box>
            <Popover>
                <PopoverTrigger><span>
                    <Tooltip hasArrow openDelay={250} label="Upload from Camera...">
                        <IconButton icon={<PiCamera />} colorScheme="blue" variant={'ghost'} />
                    </Tooltip>
                </span></PopoverTrigger>
                <PopoverContent>
                    <PopoverHeader fontWeight="semibold">Upload from Camera</PopoverHeader>
                    <PopoverArrow />
                    <PopoverBody maxHeight={'15rem'} overflowY={'auto'}>
                        <WebcamCapture onCapture={blob => { verifyThenSetContent(blob) }} />
                    </PopoverBody>
                </PopoverContent>
            </Popover>
          </Box>
        </HStack>
        {!error && <>
            {!isLoading && !hideImage && blobContent && <ChakraImage key={value.hash} src={blobContent} alt={value.hash} title={`Hash: ${value.hash}`} />}
            {isLoading && !hideImage && <Progress size='xs' isIndeterminate w={"full"} />}
            <Wrap><Badge variant="outline">{imageSize.width} x {imageSize.height}</Badge></Wrap>
        </>}
        {error && <Text fontSize={"sm"} color={"orange"}>{error}</Text>}
        <ModalUploadsBrowser
          isOpen={isUploadsOpen}
          onClose={onUploadsClose}
          onSelect={upload => {
            const REACT_APP_images_url = getEnvVariable("REACT_APP_images_url", process.env.REACT_APP_images_url);
            fetch(`${REACT_APP_images_url}/uploads/${upload._id}`)
              .then(response => response.blob())
              .then(blob => {
                verifyThenSetContent(blob);
                onUploadsClose();
              });
          }}
        />
        
      </Flex>
    );
  },

  SEED: ({ label, value, onChange, fieldName }) => {
    const [localValue, setLocalValue] = useState(value ?? 0);

    // Update local state when parent value changes
    useEffect(() => {
      setLocalValue(value ?? 0);
    }, [value]);

    return (
      <Flex direction="column" gap={2} w={'100%'}>
        <Box flex={'100%'}><FormLabel>{label}</FormLabel></Box>
        <Flex direction="row" gap={2} align="center">
          <Box flex={1}>
            <NumberInput
              value={localValue}
              min={-1}
              onChange={(valueString) => setLocalValue(parseInt(valueString))}
              onBlur={() => onChange(localValue)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <Tooltip label="Set to -1 (random seed)">
            <IconButton
              aria-label="Set to -1"
              icon={<BiShuffle />}
              onClick={() => {
                setLocalValue(-1);
                onChange(-1);
              }}
              alignSelf="flex-end"
            />
          </Tooltip>
        </Flex>
      </Flex>
    );
  },

  TEXTAREA: ({ label, value, onChange, fieldName }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [localText, setLocalText] = useState(value || '');
    const [modalText, setModalText] = useState(value || '');

    // Update local state when parent value changes
    useEffect(() => {
      setLocalText(value || '');
    }, [value]);

    return (
      <>
        <Flex direction="column" gap={1}>
          <FormLabel>{label}</FormLabel>
          <Textarea
            value={localText}
            onChange={(e) => setLocalText(e.target.value)}
            onBlur={() => onChange(localText)}
            minH="100px"
            resize="vertical"
          />
          <ButtonGroup size="sm">
            <Tooltip hasArrow label="Shuffle prompt">
              <IconButton
                variant="outline"
                onClick={() => {
                  const phrases = localText.split(',');
                  for (let i = phrases.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [phrases[i], phrases[j]] = [phrases[j], phrases[i]];
                  }
                  const shuffled = phrases.join(',');
                  setLocalText(shuffled);
                  onChange(shuffled);
                }}
                icon={<BiShuffle />}
              />
            </Tooltip>
            <Tooltip hasArrow label="Enlarge View">
              <IconButton
                variant="outline"
                onClick={() => {
                  setModalText(localText);
                  onOpen();
                }}
                icon={<FaRegKeyboard />}
              />
            </Tooltip>
          </ButtonGroup>
        </Flex>

        <Modal isOpen={isOpen} onClose={onClose} size="full">
          <ModalOverlay />
          <ModalContent height="full">
            <ModalHeader>{label}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Textarea
                value={modalText}
                onChange={(e) => setModalText(e.target.value)}
                height="100%"
                resize="none"
              />
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={() => {
                setLocalText(modalText);
                onChange(modalText);
                onClose();
              }}>
                Apply
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
};

// Base model field component
const ModelField = ({ 
  label, 
  value, 
  onChange, 
  modelType = ["Checkpoint"],
  showStrength = false,
  defaultStrength = 1.0
}) => {
  const token = useAuth().token;
  const toast = useToast();
  const [myModels, setMyModels] = useState([]);
  const [fetching, setFetching] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const hashOnly = value ? value.replace('.safetensors', '') : '';
  const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url);

  useEffect(() => {
    // If value is a SHA-256 hash, validate and use it directly
    if (value && value.match(/^[a-f0-9]{64}\.safetensors$/)) {
      setFetching(false);
      return;
    }

    setFetching(true);
    fetch(`${REACT_APP_api_url}/v3/my_models/${modelType[0]}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ type: "Flux" })
    }).then((response) => response.json())
    .then(actualdata => {
      let model_hashes = {};
      let models = [];
      actualdata.forEach(model => {
        if (!model_hashes[model.hash]) {
          models.push(model);
          model_hashes[model.hash] = true;
        }
      });
      setMyModels(models);
      setFetching(false);
    });
  }, [token, REACT_APP_api_url, modelType, value]);

  const handleSelect = hash => {
    onChange(hash);
  };

  return (
    <Flex direction="column" gap={2}>
      <Flex align="center" justify="space-between">
        <FormLabel mb={0}>{label}</FormLabel>
        <Tooltip hasArrow label={`Browse ${modelType[0]}s`}>
          <IconButton
            size="sm"
            variant="ghost"
            icon={<BiLayerPlus />}
            onClick={onOpen}
          />
        </Tooltip>
      </Flex>
      <ModalModelBrowser 
        onSelect={handleSelect} 
        isOpen={isOpen} 
        onClose={onClose} 
        modelType={modelType}
        hide={{
          sort: false, 
          modelTypes: true, 
          modelArchitecture: false
        }}
      />
      <Wrap w="full">
        {fetching && <Progress size='xs' isIndeterminate w="full" />}
        {value && (
          <ModelButton
            modelArchitecture={["Flux", "sd-1.5", "sdxl"]}
            id={hashOnly}
            strength_model={showStrength ? defaultStrength : undefined}
            strength_clip={showStrength ? defaultStrength : undefined}
            onChange={handleSelect}
          />
        )}
      </Wrap>
    </Flex>
  );
};

// Custom fields for specific node types
const customNodeFields = {
  CLIPTextEncode: {
    text: BaseFields.TEXTAREA
  },
  DualCLIPTextEncode: {
    text: BaseFields.TEXTAREA,
    text2: BaseFields.TEXTAREA
  },
  CLIPTextEncodeFlux: {
    clip_l: BaseFields.TEXTAREA,
    t5xxl: BaseFields.TEXTAREA
  },
  LoraLoader: {
    lora_name: ({ label, value, onChange }) => (
      <ModelField
        label={label}
        value={value}
        onChange={onChange}
        modelType={["LORA"]}
        showStrength={true}
      />
    ),
  },
  CheckpointLoaderSimple: {
    ckpt_name: ({ label, value, onChange }) => (
      <ModelField
        label={label}
        value={value}
        onChange={onChange}
        modelType={["Checkpoint"]}
      />
    ),
  },
  CLIPLoader: {
    clip_name: BaseFields.CLIP
  },
  DualCLIPLoader: {
    clip_name1: BaseFields.CLIP,
    clip_name2: BaseFields.CLIP
  },
  TripleCLIPLoader: {
    clip_name1: BaseFields.CLIP,
    clip_name2: BaseFields.CLIP,
    clip_name3: BaseFields.CLIP
  },
  KSampler: {
    seed: BaseFields.SEED
  },
  KSamplerSDXLAdvanced: {
    seed: BaseFields.SEED
  },
  UpscaleModelLoader: {
    model_name: BaseFields.MODEL
  },
  LoadImage: {
    image: BaseFields.IMAGE
  }
};

// Get the appropriate field component
export const getFieldComponent = (nodeType, fieldName, fieldType) => {
  // Check for custom node field first
  const customField = customNodeFields[nodeType]?.[fieldName];
  if (customField) {
    return (props) => customField({ ...props, fieldName });
  }

  // Fallback to base field type with fieldName
  const BaseComponent = BaseFields[fieldType] || BaseFields.STRING;
  return (props) => BaseComponent({ ...props, fieldName });
};

const TextField = ({ value: initialValue, onChange, ...props }) => {
  const [localValue, setLocalValue] = useState(initialValue);

  return (
    <Input
      value={localValue}
      onChange={(e) => {
        setLocalValue(e.target.value);
        // Don't call onChange here
      }}
      onBlur={() => {
        // Only update parent when field loses focus
        if (localValue !== initialValue) {
          onChange(localValue);
        }
      }}
      {...props}
    />
  );
};
