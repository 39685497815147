import { useEffect, useState, useCallback } from 'react'
import {
    Badge, Wrap, Progress, Tooltip, Input, Text, Image as ChakraImage, FormLabel, useToast,
    Box, Popover, PopoverBody, PopoverTrigger, PopoverArrow, IconButton, PopoverContent, PopoverHeader,
    useDisclosure
} from '@chakra-ui/react'

import { PropBox } from './PropBox'
import WebcamCapture from 'components/shared/WebcamCapture'
import FileDropZone from 'components/shared/FileDropZone'
import { getEnvVariable } from 'utils/env'
import { calculateBlobSHA256 } from 'utils/pieceUtils'
import { AiOutlineCloudUpload } from "react-icons/ai"
import { TfiGallery } from "react-icons/tfi";
import { BiPaste } from 'react-icons/bi'
import { PiUpload, PiCamera } from "react-icons/pi"
import { ModalUploadsBrowser } from 'components/shared/ModalUploadsBrowser'

/**
 * Renders the ImageOptions component.
 * @param {Object} props - The component props.
 * @param {Object} props.value - The current value of the image options.
 * @param {Function} props.onChange - The function to call when the value changes.
 * @param {string} props.label - The label for the image options.
 * @param {boolean} props.hideImage - Whether to hide the image.
 * @returns {JSX.Element} The rendered image options component.
 */
export const ImageOptions = ({
    value = { "hash": null, "content": null },
    onChange = () => { console.warn("No onChange function provided") },
    hideImage = false,
    maxPixels = 1280 * 1280,
    label = "Image"
}) => {
    const toast = useToast()
    const [error, setError] = useState('')
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 })
    const [blobContent, setBlobContent] = useState("")
    const [url, setUrl] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const { isOpen : isUploadsOpen, onClose : onUploadsClose, onOpen : onUploadsOpen } = useDisclosure()

    const updateValue = useCallback(v => { onChange(v) }, [onChange])

    const isImageTooLarge = useCallback((width, height) => {
        return width * height > maxPixels
    }, [maxPixels])

    const verifyThenSetContent = useCallback(blob => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = URL.createObjectURL(blob);
        img.onerror = (e) => {
            console.log("Error loading image", e);
            setError(`Error loading image. Please check the URL or file.`);
            setIsLoading(false);
        };

        img.onload = () => {
            const processImage = async (blob) => {
                let h = await calculateBlobSHA256(blob);
                setError("");
                if (h !== value.hash || blob !== value.content) {
                    // console.log("Setting image content", h, blob);
                    updateValue({ hash: h, content: blob });
                }
            }
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, img.width, img.height);
            canvas.toBlob(processImage, 'image/png');
        };

    }, [updateValue, value.hash, value.content]);

    const lookupContentByHash = useCallback(async (h) => {
        // console.log("Retrieving content for hash", h)
        if (h) {
            const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
            const REACT_APP_images_url = getEnvVariable("REACT_APP_images_url", process.env.REACT_APP_images_url)
            setIsLoading(true)
            fetch(`${REACT_APP_api_url}/v3/getimagehash/${h}`).then(response => {
                if (response.status === 200) {
                    return response.json()
                }
            }).then(lookupContent => {
                setIsLoading(false)
                if (lookupContent === null) {
                    console.log(`No metadata found for hash ${h}, probably a new upload.`)
                } else {
                    // console.log(lookupContent)
                    setImageSize({ width: lookupContent.width, height: lookupContent.height })
                    // Set value.content to Blob from hash
                    fetch(`${REACT_APP_images_url}/uploads/${h}`).then(response => response.blob()).then(blob => {
                        verifyThenSetContent(blob)
                    })
                }
            })
        }
    }, [])

    useEffect(() => {
        if (value.content && value.content instanceof Blob) {
            setBlobContent(URL.createObjectURL(value.content))
        }
    }, [value.content, setBlobContent])

    useEffect(() => {
        lookupContentByHash(value.hash)
    }, [value.hash, lookupContentByHash])

    const urlChangeHandler = (url) => {
        setUrl(url)
        setIsLoading(true)
        // Load image URL to get hash
        // Create DOM element to load content
        const img = new Image()
        img.crossOrigin = "Anonymous"
        img.src = url
        img.onerror = (e) => {
            setError(`Please check if the URL is correct and points directly to an image file.
            If the URL is correct, the image may not be accessible due to CORS restrictions.  
            Check the browser console for more information.
            `)
            setIsLoading(false)
        }

        img.onload = async () => {
            setError("")
            // Create canvas to draw image
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0)
            // Get image data from canvas
            canvas.toBlob((blob) => {
                verifyThenSetContent(blob)
                setIsLoading(false)
            }, 'image/png')
        }
    }

    return <PropBox type="image" label={label} value={value} onChange={v => updateValue(v)}>
            <ModalUploadsBrowser isOpen={isUploadsOpen} onClose={onUploadsClose}
                onSelect={upload => {
                    onChange({...value, hash: upload._id, content: null })
                    onUploadsClose()
                }}/>
        <Wrap>
            {/* <InfinityFeed page={params.page} path={`/v3/my/uploads/${params.user_id}${order}`} mode="uploads" source="uploads" method="continuation" /> */}
            {/* User Uploads */}
            <Box>
                <Tooltip hasArrow openDelay={250} label="Select from Uploads...">
                    <IconButton icon={<TfiGallery />} colorScheme="blue" variant={'ghost'} onClick={e=>{
                        onUploadsOpen()
                    }}/>
                </Tooltip>
            </Box>

            {/* Upload from URL */}
            <Box>
                <Popover>
                    <PopoverTrigger><span>
                        <Tooltip hasArrow openDelay={250} label="Upload from URL...">
                            <IconButton icon={<AiOutlineCloudUpload />} colorScheme="blue" variant={'ghost'} />
                        </Tooltip>
                    </span></PopoverTrigger>
                    <PopoverContent>
                        <PopoverHeader fontWeight="semibold">Upload from URL</PopoverHeader>
                        <PopoverArrow />
                        <PopoverBody maxHeight={'15rem'} overflowY={'auto'}>
                            <Tooltip hasArrow label="Provide a valid direct URL to a JPG or PNG that you want to use an image" openDelay={250}>
                                <FormLabel htmlFor="url">Image URL</FormLabel>
                            </Tooltip>
                            <Input id="url" placeholder="URL here" value={url} onChange={e => urlChangeHandler(e.target.value)} />
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </Box>

            {/* Camera Upload */}
            <Box>
                <Popover>
                    <PopoverTrigger><span>
                        <Tooltip hasArrow openDelay={250} label="Upload from Camera...">
                            <IconButton icon={<PiCamera />} colorScheme="blue" variant={'ghost'} />
                        </Tooltip>
                    </span></PopoverTrigger>
                    <PopoverContent>
                        <PopoverHeader fontWeight="semibold">Upload from Camera</PopoverHeader>
                        <PopoverArrow />
                        <PopoverBody maxHeight={'15rem'} overflowY={'auto'}>
                            <WebcamCapture onCapture={blob => { verifyThenSetContent(blob) }} />
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </Box>

            {/* Upload from File */}
            <Box>
                <Popover>
                    <PopoverTrigger><span>
                        <Tooltip hasArrow openDelay={250} label="Upload from File...">
                            <IconButton icon={<PiUpload />} colorScheme="blue" variant={'ghost'} />
                        </Tooltip>
                    </span></PopoverTrigger>
                    <PopoverContent>
                        <PopoverHeader fontWeight="semibold">Upload from File</PopoverHeader>
                        <PopoverArrow />
                        <PopoverBody maxHeight={'15rem'} overflowY={'auto'}>
                            <FileDropZone onDrop={blob => { verifyThenSetContent(blob) }} />
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </Box>

            {/* Paste Image */}
            <Tooltip hasArrow openDelay={250} label="Paste Image">
                <span>
                    <IconButton
                        // isLoading={isGenerating}
                        icon={<BiPaste />}
                        colorScheme="blue"
                        variant={'ghost'}
                        onClick={() => {
                            navigator.clipboard.read().then(items => {
                                let imageFound = false
                                // Loop through clipboard items
                                for (let i = 0; i < items.length; i++) {
                                    let item = items[i]
                                    // Check if the item is an image (PNG, JPEG, or WebP)
                                    if (item.types.includes("image/png") || item.types.includes("image/jpeg") || item.types.includes("image/webp")) {
                                        imageFound = true
                                        let imageType = item.types.find(type => type.includes("image/"))
                                        // Get the image as a blob of the identified type
                                        item.getType(imageType).then(blob => {
                                            verifyThenSetContent(blob)
                                        }).catch(e => {
                                            console.error(e)
                                            toast({
                                                title: "Error",
                                                description: "Failed to read image from clipboard.",
                                                status: "error"
                                            })
                                        })
                                        break // Exit the loop after finding the first image
                                    }
                                }
                                if (!imageFound) {
                                    // Display a toast if no image is found
                                    toast({
                                        title: "No Image Found",
                                        description: "Could not find an image in the clipboard.",
                                        status: "warning"
                                    })
                                }
                            }).catch(e => {
                                console.error(e)
                                toast({
                                    title: "Error",
                                    description: "Clipboard access denied.",
                                    status: "error"
                                })
                            })
                        }}
                    />
                </span>
            </Tooltip>

        </Wrap>
        {!error && <>
            {!isLoading && !hideImage && blobContent && <ChakraImage key={value.hash} src={blobContent} alt={value.hash} title={`Hash: ${value.hash}`} />}
            {isLoading && !hideImage && <Progress size='xs' isIndeterminate w={"full"} />}
            <Wrap><Badge variant="outline">{imageSize.width} x {imageSize.height}</Badge></Wrap>
        </>}
        {error && <Text fontSize={"sm"} color={"orange"}>{error}</Text>}
    </PropBox>
}