import { useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  MiniMap,
  applyNodeChanges,
  applyEdgeChanges,
  useReactFlow,
  MarkerType,
  ControlButton,
  addEdge,
  reconnectEdge
} from 'reactflow';
import { Box, useToast } from '@chakra-ui/react';
import 'reactflow/dist/style.css';
import './FlowView.css';
import FlowNode from './FlowNode';

// Node types
const nodeTypes = {
  custom: FlowNode,
};

function Flow({ data, objectInfo, onDeleteNode, onUpdate, onZoomToNode, onEdgeAdd, onNodePositionChange }) {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [initialized, setInitialized] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const isFirstLayout = useRef(true);
  const nodePositions = useRef(new Map());  // Store positions by IdNumber
  const reactFlowInstance = useRef(null);
  const toast = useToast();
  const { fitView, setCenter } = useReactFlow();

  // Store initial viewport
  const initialViewport = {
    x: 0,
    y: 0,
    zoom: 0.5
  };

  // Handle ReactFlow instance ready
  const onInit = useCallback((instance) => {
    reactFlowInstance.current = instance;
    // Set consistent initial viewport
    instance.setViewport(initialViewport);
  }, []);

  // Handle edge changes including deletion
  const handleEdgesChange = useCallback((changes) => {
    // Handle edge removal
    const removeChanges = changes.filter(change => change.type === 'remove');
    if (removeChanges.length > 0) {
      removeChanges.forEach(change => {
        const edgeId = change.id;
        const [sourceId, sourceHandle, targetId, targetHandle] = edgeId.split('-');
        
        if (onUpdate && data?.pipeline && targetId && targetHandle) {
          const newPipeline = { ...data.pipeline };
          if (newPipeline[targetId]?.inputs?.[targetHandle]) {
            delete newPipeline[targetId].inputs[targetHandle];
            onUpdate(newPipeline);
            
            toast({
              title: "Connection Removed",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
        }
      });
    }
    
    onEdgesChange(changes);
  }, [onEdgesChange, data?.pipeline, onUpdate, toast]);

  // Called when edge dragging starts
  const onEdgeUpdateStart = useCallback(() => {
    console.log('Edge update started');
  }, []);

  // Called during edge drag
  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    console.log('Edge update:', { oldEdge, newConnection });
    if (newConnection) {
      // If there's a new connection, update the edge
      return setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
    }
    // If dropped without connection, mark as unsuccessful
  }, [setEdges]);

  // Called when edge drag ends
  const onEdgeUpdateEnd = useCallback((event, edge) => {
    console.log('Edge update end:', { edge });
    
    // Only delete if drag ended without a successful connection
    if (edge) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
      
      const [sourceId, sourceHandle, targetId, targetHandle] = edge.id.split('-');
      if (onUpdate && data?.pipeline && targetId && targetHandle) {
        const newPipeline = { ...data.pipeline };
        if (newPipeline[targetId]?.inputs?.[targetHandle]) {
          delete newPipeline[targetId].inputs[targetHandle];
          onUpdate(newPipeline);
          
          toast({
            title: "Connection Removed",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
      }
    }
  }, [setEdges, data?.pipeline, onUpdate, toast]);

  // Handle new connections
  const onConnect = useCallback((params) => {
    console.log('Attempting to connect:', params);
    if (onEdgeAdd) {
      onEdgeAdd({
        source: params.source,
        sourceHandle: params.sourceHandle || '0',
        target: params.target,
        targetHandle: params.targetHandle
      });
    }
  }, [onEdgeAdd]);

  // Handle node drag (during dragging)
  const onNodeDrag = useCallback((event, node) => {
    const position = { ...node.position };
    nodePositions.current.set(node.id, position);
  }, []);

  // Handle node drag stop (when released)
  const onNodeDragStop = useCallback((event, node) => {
    const position = { ...node.position };
    
    // Only update if position actually changed
    const currentPos = nodePositions.current.get(node.id);
    if (!currentPos || 
        currentPos.x !== position.x || 
        currentPos.y !== position.y) {
      
      nodePositions.current.set(node.id, position);
      onNodePositionChange?.(node.id, position);
    }
  }, [onNodePositionChange]);


  // Update nodes when data changes
  useEffect(() => {
    if (!data?.flow?.nodes) return;
    
    const updatedNodes = data.flow.nodes.map(node => ({
      ...node,
      // Use stored position if available, otherwise keep current
      position: nodePositions.current.get(node.id) || node.position,
      data: {
        ...node.data,
        onDeleteNode
      }
    }));
    
    setNodes(updatedNodes);
    setEdges(data.flow.edges || []);
  }, [data, setNodes, setEdges, onDeleteNode]);


  // Handle zoom to node requests
  useEffect(() => {
    if (!onZoomToNode || !reactFlowInstance.current) return;

    // Look for node by IdNumber in node.data
    const node = nodes.find(n => n.data?.IdNumber === onZoomToNode);
    if (!node) {
      console.log('FlowView: Node not found for IdNumber:', onZoomToNode);
      return;
    }

    console.log('Zooming to node:', node);

    // Calculate node center
    const nodeWidth = node.width || 272;
    const nodeHeight = node.height || 197;
    const centerX = node.position.x + nodeWidth / 2;
    const centerY = node.position.y + nodeHeight / 2;

    // Get viewport dimensions
    const vp = reactFlowInstance.current.getViewport();
    
    // Set viewport to center on node with single transition
    reactFlowInstance.current.setViewport(
      {
        x: -centerX * vp.zoom + window.innerWidth / 2 - 230,
        y: -centerY * vp.zoom + window.innerHeight / 2 - 200,
        zoom: 1
      },
      { duration: 800 }
    );

  }, [onZoomToNode, nodes]);

  // Fit view only on initial load
  useEffect(() => {
    if (!initialized && data.pipeline.length> 0) {
      setTimeout(() => {
        fitView({ padding: 0.2 });
      }, 100);
    }
  }, [initialized, data.pipeline.length, fitView]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={handleEdgesChange}
      onConnect={onConnect}
      onEdgeUpdate={onEdgeUpdate}
      onNodeDrag={onNodeDrag}
      onNodeDragStop={onNodeDragStop}
      onEdgeUpdateStart={onEdgeUpdateStart}
      onEdgeUpdateEnd={onEdgeUpdateEnd}
      onDeleteNode={onDeleteNode}
      nodeTypes={nodeTypes}
      onInit={onInit}
      defaultViewport={initialViewport}
      minZoom={0.3}
      maxZoom={1}
      onZoomToNode={onZoomToNode}
      fitView={isFirstLayout.current}
      deleteKeyCode="Delete"
      multiSelectionKeyCode="Control"
      selectionKeyCode="Shift"
      edgesUpdatable={true}
    >
      <Controls />
      <Background variant="dots" gap={12} size={1} />
    </ReactFlow>
  );
}

export function FlowView(props) {
  return (
    <Box
      position="relative"
      className="flow-editor"
    >
      <ReactFlowProvider>
        <Flow {...props} />
      </ReactFlowProvider>
    </Box>
  );
}

export default FlowView;
