import { sha256 } from 'js-sha256';

/**
 * Utility functions for converting editor format to pipeline format
 */

/**
 * Constants for node types and parameters
 */
const NODE_TYPES = {
  VAE_LOADER: 'VAELoader',
  DUAL_CLIP_LOADER: 'DualCLIPLoader',
  CLIP_TEXT_ENCODE: 'CLIPTextEncode',
  UNET_LOADER: 'UNETLoader',
  LORA_LOADER: 'LoraLoader',
  MODEL_SAMPLING_FLUX: 'ModelSamplingFlux',
  FLUX_GUIDANCE: 'FluxGuidance',
  CONDITIONING_ZERO_OUT: 'ConditioningZeroOut',
  EMPTY_LATENT_IMAGE: 'EmptyLatentImage',
  KSAMPLER: 'KSampler',
  VAE_DECODE: 'VAEDecode',
  PREVIEW_IMAGE: 'PreviewImage'
};

/**
 * Model architecture constants
 */
const MODEL_ARCHITECTURES = {
  FLUX: 'flux',
  SD15: 'sd-1.5',
  SDXL: 'sdxl',
  SD3: 'sd-3',
  SDXL_TURBO: 'sdxl-turbo'
};

/**
 * Map of node class types to model types
 */
export const MODEL_TYPE_MAP = {
  'CheckpointLoaderSimple': 'Checkpoint',
  'VAELoader': 'VAE',
  'CLIPLoader': 'CLIP',
  'DualCLIPLoader': 'CLIP',
  'LoraLoader': 'LORA',
  'ControlNetLoader': 'Controlnet',
  'TextualInversionLoader': 'TextualInversion',
  'UNETLoader': 'Checkpoint'
};

/**
 * Map of node class types to image types
 */
export const IMAGE_TYPE_MAP = {
  'LoadImage': 'Image',
  'ImageUpload': 'Image',
  'LoadImageFromUrl': 'Image'
};

/**
 * Detect architecture from model
 * @param {Object} modelNode Model node data
 * @returns {string} Detected architecture
 */
const detectArchitecture = (modelNode) => {
  if (!modelNode) return MODEL_ARCHITECTURES.SD15; // Default to SD1.5

  const { model, class_type } = modelNode.data;
  
  // Check model metadata first
  if (model?.metadata?.architecture) {
    return model.metadata.architecture;
  }

  // Check by model hash patterns or names
  if (model?.hash) {
    // Flux models typically have specific hash patterns or metadata
    if (class_type === 'UNETLoader' || model.name?.toLowerCase().includes('flux')) {
      return MODEL_ARCHITECTURES.FLUX;
    }
    
    // SDXL models detection
    if (model.name?.toLowerCase().includes('xl') || 
        class_type === 'CheckpointLoaderSimpleSDXL' ||
        model.baseModel?.toLowerCase().includes('xl')) {
      return MODEL_ARCHITECTURES.SDXL;
    }
  }

  // Default to SD1.5 if no specific identifiers found
  return MODEL_ARCHITECTURES.SD15;
};

/**
 * Convert model name to filename format
 * @param {string} modelName Original model name
 * @returns {string} Formatted filename
 */
export const formatModelFilename = (modelName) => {
  if (!modelName) return '';
  return modelName.endsWith('.safetensors') 
    ? modelName 
    : modelName
        .toLowerCase()
        .replace(/[\s()]/g, '')
        .replace(/[^a-z0-9._-]/g, '')
        + '.safetensors';
};

/**
 * Extract parameters from node data
 * @param {Object} node Node data
 * @returns {Object} Extracted parameters
 */
export const extractNodeParams = (node) => {
  const params = {};
  
  if (!node.data || !node.data.inputs) return params;
  
  Object.entries(node.data.inputs).forEach(([key, value]) => {
    // Skip array inputs (connections)
    if (Array.isArray(value)) return;
    
    // Convert numeric strings to numbers
    if (typeof value === 'string' && !isNaN(value)) {
      params[key] = Number(value);
    } else {
      params[key] = value;
    }
  });
  
  return params;
};

/**
 * Extract asset information from node
 * @param {Object} node Node data
 * @returns {Object} Asset information
 */
export const extractAssetInfo = (node) => {
  if (!node.data) return null;

  const { class_type, inputs } = node.data;
  
  if (NODE_TYPES.VAE_LOADER === class_type || NODE_TYPES.DUAL_CLIP_LOADER === class_type || NODE_TYPES.UNET_LOADER === class_type) {
    return {
      type: 'model',
      name: inputs.model_name || inputs.ckpt_name || inputs.vae_name || inputs.clip_name,
      hash: inputs.hash || null
    };
  }
  
  if (NODE_TYPES.LORA_LOADER === class_type) {
    return {
      type: 'model',
      name: inputs.name,
      hash: inputs.hash || null
    };
  }
  
  if (NODE_TYPES.EMPTY_LATENT_IMAGE === class_type) {
    return {
      type: 'image',
      name: inputs.image_name || inputs.url,
      hash: inputs.hash || null
    };
  }
  
  return null;
};

/**
 * Process a model node and return asset data
 * @param {Object} node Node data
 * @returns {Promise<Object>} Model asset data with hash
 */
export const processModelNode = async (node) => {
  const modelName = node.data.inputs?.model_name || node.data.inputs?.ckpt_name;
  if (!modelName || !MODEL_TYPE_MAP[node.data.class_type]) return null;

  const modelDetails = {
    name: modelName,
    type: MODEL_TYPE_MAP[node.data.class_type],
    baseModel: node.data.inputs?.base_model || "4610115bb0c89560703c892c59ac2742fa821e60ef5871b33493ba544683abd7"
  };

  const hash = await sha256(JSON.stringify(modelDetails));
  return {
    hash,
    asset: {
      name: modelName,
      baseModel: node.data.inputs?.base_model || "4610115bb0c89560703c892c59ac2742fa821e60ef5871b33493ba544683abd7",
      type: MODEL_TYPE_MAP[node.data.class_type],
      filename: formatModelFilename(modelName),
      extension: "safetensors"
    }
  };
};

/**
 * Process an image node and return asset data
 * @param {Object} node Node data
 * @returns {Promise<Object>} Image asset data with hash
 */
export const processImageNode = async (node) => {
  const imageData = node.data.inputs?.image;
  const imageUrl = node.data.inputs?.url;
  
  if (!imageData && !imageUrl) return null;

  // Handle URL-based images
  if (imageUrl) {
    const hash = await sha256(imageUrl);
    return {
      hash,
      asset: {
        url: imageUrl,
        type: 'url',
        width: node.data.inputs.width || 512,
        height: node.data.inputs.height || 768,
        extension: node.data.inputs.extension || "png"
      }
    };
  }

  // Handle direct image data
  const hash = await sha256(imageData);
  return {
    hash,
    asset: {
      data: imageData,
      type: 'data',
      width: node.data.inputs.width || 512,
      height: node.data.inputs.height || 768,
      extension: node.data.inputs.extension || "png"
    }
  };
};

/**
 * Convert flow data to pipeline format
 * @param {Object} nodes Flow nodes
 * @param {Object} edges Flow edges
 * @returns {Object} Pipeline data
 */
export const flowToPipeline = (nodes, edges) => {
  console.log(nodes)
  // Validate input
  if (!nodes || !Array.isArray(nodes) || nodes.length === 0) {
    let msg = 'Invalid nodes data for pipeline conversion'
    alert(msg);
    console.warn(msg);
    return {
      pipeline: {},
      assets: { models: {}, images: {} },
      timestamp: new Date().toISOString(),
      architecture: "flux",
      status: 'pending',
      origin: 'web',
      private: false,
      priority: 'medium',
      params: {},
      id: generateId()
    };
  }

  const pipeline = {};
  const assets = { models: {}, images: {} };

  // Process nodes
  nodes.forEach(node => {
    if (!node.data || !node.data.class_type) return;

    // Format node ID
    const nodeId = node.id.toLowerCase().replace(/[^a-z0-9_]/g, '_');

    // Get input connections
    const connections = edges
      .filter(e => e.target === node.id)
      .reduce((acc, edge) => {
        const sourceNode = nodes.find(n => n.id === edge.source);
        if (sourceNode) {
          const sourceId = sourceNode.id.toLowerCase().replace(/[^a-z0-9_]/g, '_');
          acc[edge.targetHandle] = [sourceId, parseInt(edge.sourceHandle) || 0];
        }
        return acc;
      }, {});

    // Merge node inputs with connections
    const inputs = { ...node.data.inputs, ...connections };

    // Add node to pipeline
    pipeline[nodeId] = {
      class_type: node.data.class_type,
      inputs
    };

    // Track model assets
    if (node.data.model?.hash) {
      assets.models[node.data.model.hash] = {
        name: node.data.model.name,
        baseModel: node.data.model.baseModel,
        type: MODEL_TYPE_MAP[node.data.class_type] || 'Unknown',
        filename: formatModelFilename(node.data.model.name),
        extension: 'safetensors'
      };
    }
  });

  return {
    pipeline,
    assets,
    timestamp: new Date().toISOString(),
    architecture: detectArchitecture(nodes),
    status: 'pending',
    origin: 'web',
    private: false,
    priority: 'medium',
    params: extractJobParamsFromNodes(nodes),
    id: generateId()
  };
};

/**
 * Extract job parameters from pipeline
 * @param {Object} pipeline Pipeline data
 * @returns {Object} Job parameters
 */
export const extractJobParams = (pipeline, options = {}, objectInfo = {}) => {
  const {
    parent_id = "new",
    debug = false,
    isPrivate = false,
    priority = "medium",
    id,
    author
  } = options;

  // Generate and validate ID
  const jobId = id || generateId();
  if (!jobId || typeof jobId !== 'string') {
    console.error('Invalid job ID:', jobId);
    throw new Error('Valid job ID string is required');
  }

  // Create pipeline nodes with proper IDs
  const pipelineNodes = {};
  Object.entries(pipeline).forEach(([id, node]) => {
    const nodeId = id.toLowerCase().replace(/[^a-z0-9_]/g, '_');
    pipelineNodes[nodeId] = {
      class_type: node.class_type,
      inputs: { ...node.inputs } // Clone inputs to avoid reference issues
    };
  });

  return {
    _id: jobId,
    pipeline: pipelineNodes,
    assets: {
      models: {}, // Will be populated from model nodes
      images: {}
    },
    timestamp: new Date().toISOString(),
    status: "queued",
    architecture: "comfy-pipeline",
    author,
    origin: "web",
    private: isPrivate,
    priority: priority,
    params: {
      parent_id,
      debug
    }
  };
};

/**
 * Extract job parameters from nodes
 * @param {Array} nodes Flow nodes
 * @returns {Object} Job parameters
 */
const extractJobParamsFromNodes = (nodes) => {
  return { };
};

/**
 * Generate a unique ID for the job
 * @returns {string} Hash string to use as job ID
 */
export const generateId = () => {
  const timestamp = new Date().getTime();
  const random = Math.random().toString();
  const data = `${timestamp}-${random}`;
  // js-sha256's sha256 function returns a string directly
  const hash = sha256.create().update(data).hex();
  return hash;
};

/**
 * Calculate hash for image data
 * @param {Blob|string} imageData Image data to hash
 * @returns {string} Hash of the image data
 */
export const calculateImageHash = (imageData) => {
  if (imageData instanceof Blob) {
    // Convert blob to string for hashing
    const str = imageData.size + imageData.type;
    return sha256.create().update(str).hex();
  }
  return sha256.create().update(String(imageData)).hex();
};
