import {
    Box, MenuButton, Menu, MenuList, MenuDivider, MenuOptionGroup, MenuItemOption,
    IconButton, Tooltip
} from '@chakra-ui/react'

import { BsStars } from 'react-icons/bs'

/**
 * Renders a menu with extra features for workshop.
 * @param {Object} value - The current value of the features.
 * @param {string} variant - The variant of the menu button.
 * @param {Function} onChange - The function to call when the value of the features changes.
 * @returns {JSX.Element} The rendered ExtrasPicker component.
 */
export const ExtrasPicker = ({
    architecture="stable-diffusion",
    value,
    variant,
    onChange = () => console.warn("No ExtrasPicker onChange handler.")
}) => {
    let enabledFeatures = []
    if(architecture === "stable-diffusion") {
        if (value?.upscaler?.enabled) enabledFeatures.push("upscaler")
        if (value?.faceDetailer?.enabled) enabledFeatures.push("faceDetailer")
        if (value?.controlnet?.enabled) enabledFeatures.push("controlnet_enabled")
        if (value?.ipadapter?.enabled) enabledFeatures.push("ipadapter_enabled")
        if (value?.faceRestore?.enabled) enabledFeatures.push("faceRestore")
        if (value?.roop?.enabled) enabledFeatures.push("roop_enabled")
    }
    if(architecture === "flux") {
        if (value?.upscaler?.enabled) enabledFeatures.push("upscaler")
        if (value?.controlnet?.enabled) enabledFeatures.push("controlnet_enabled")
        if (value?.faceDetailer?.enabled) enabledFeatures.push("faceDetailer")
    }

    const updateValue = function (selectedFeatures) {
        // alert(JSON.stringify(selectedFeatures))
        let upscaler = false
        let faceDetailer = false
        let controlnet_enabled = false
        let ipadapter_enabled = false
        let faceRestore = false
        let roop_enabled = false

        selectedFeatures.forEach(f => {
            if (f === "upscaler") upscaler = true
            if (f === "faceDetailer") faceDetailer = true
            if (f === "faceRestore") faceRestore = true
            if (f === "controlnet_enabled") controlnet_enabled = true
            if (f === "ipadapter_enabled") ipadapter_enabled = true
            if (f === "roop_enabled") roop_enabled = true
        })
        
        const newValue = {
            ...value,
            controlnet: { ...value.controlnet, enabled: controlnet_enabled },
            ipadapter: { ...value.ipadapter, enabled: ipadapter_enabled },
            faceRestore: { ...value.faceRestore, enabled: faceRestore },
            roop: { ...value.roop, enabled: roop_enabled },
            upscaler: { ...value.upscaler, enabled: upscaler },
            faceDetailer: { ...value.faceDetailer, enabled: faceDetailer }
        }
        onChange(newValue)
    }
    const menuItems = []
    if(["flux","stable-diffusion"].includes(architecture)){
        menuItems.push(<MenuItemOption closeOnSelect={false} value='upscaler'>Upscaler</MenuItemOption>)
        menuItems.push(<MenuItemOption closeOnSelect={false} value='controlnet_enabled'>ControlNet</MenuItemOption>)
        menuItems.push(<MenuItemOption closeOnSelect={false} value='faceDetailer'>Face Detailer</MenuItemOption>)
    }
    if(["stable-diffusion"].includes(architecture)){
        menuItems.push(<MenuItemOption closeOnSelect={false} value='ipadapter_enabled'>IP-Adapter</MenuItemOption>)
        menuItems.push(<MenuDivider />)
        menuItems.push(<MenuItemOption closeOnSelect={false} value='faceRestore'>Face Restore</MenuItemOption>)
        menuItems.push(<MenuItemOption closeOnSelect={false} value='roop_enabled'>Roop</MenuItemOption>)
    }
    // Wrapper box required to suppress console spam (https://github.com/chakra-ui/chakra-ui/issues/3440#issuecomment-851707911)
    return <Box>
        <Menu>
            <Tooltip hasArrow label="Extra Features...">
                <MenuButton
                    variant={variant}
                    icon={<BsStars />}
                    colorScheme={"purple"}
                    as={IconButton}
                />
            </Tooltip>
            <MenuList>
                <MenuOptionGroup value={enabledFeatures} title='Extra Features' type='checkbox' onChange={v => { updateValue(v) }}>
                    {menuItems}
                </MenuOptionGroup>
            </MenuList>
        </Menu>
    </Box>
}