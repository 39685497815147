//
import dagre from 'dagre';

// Keep position cache
let positionCache = new Map();

// Keep pipeline type for reference
let currentPipelineType = null;

const calculateNodeHeight = (node, nodeInfo) => {
  if (!node || !nodeInfo) return 120;
  let height = 80;
  const inputCount = Object.keys(nodeInfo.input?.required || {}).length +
                    Object.keys(nodeInfo.input?.optional || {}).length;
  height += inputCount * 30;
  if (nodeInfo.description) {
    height += 20;
  }
  height += 40;
  return Math.max(height, 120);
};

const getLayoutedElements = (nodes, edges, direction = 'LR', forceLayout = false) => {
  // For initial layout or forced layout, use dagre
  if (forceLayout || !positionCache.size) {
    const nodeWidth = 300;
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({
      rankdir: direction,
      nodesep: 200,
      ranksep: 150,
      edgesep: 100,
      acyclicer: 'greedy',
      ranker: 'network-simplex',
      align: 'UL',
      marginx: 50,
      marginy: 50
    });

    // Add nodes to dagre
    nodes.forEach((node) => {
      const nodeHeight = calculateNodeHeight(
        node.data?.nodeData,
        node.data?.nodeObjectInfo
      );
      dagreGraph.setNode(node.id, { width: nodeWidth + 40, height: nodeHeight + 20 });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    // Get initial positions
    const layoutedNodes = nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      const position = {
        x: nodeWithPosition.x - (nodeWidth + 40) / 2,
        y: nodeWithPosition.y - (nodeWithPosition.height / 2)
      };
      positionCache.set(node.id, position);
      return { ...node, position };
    });

    return { nodes: layoutedNodes, edges };
  }

  // Otherwise, use existing positions
  const layoutedNodes = nodes.map(node => {
    const position = node.position || positionCache.get(node.id) || { x: 0, y: 0 };
    return { ...node, position };
  });

  return { nodes: layoutedNodes, edges };
};

// Update position when node is moved
const updateNodePosition = (nodeId, position) => {
  if (!positionCache) return;
  positionCache.set(nodeId, position);
};

// Clear positions when loading new pipeline
const clearPositionCache = () => {
  positionCache = new Map();
};

// Helper to normalize node ID format
const normalizeNodeId = (id) => {
  // For named nodes, keep the original ID
  if (typeof id === 'string' && isNaN(id)) {
    return id;
  }
  // For numeric or numeric string IDs, convert to string
  return id.toString();
};

// Helper to normalize node structure
const normalizeNode = (id, node, index) => {
  // Keep original ID if it's a named node
  const nodeId = isNaN(id) ? id : parseInt(id);
  
  const normalizedNode = {
    ...node,
    class_type: node.class_type,
    // Use original ID for IdNumber if it's a named node
    IdNumber: node.IdNumber || (isNaN(nodeId) ? index + 1 : nodeId),
    inputs: { ...node.inputs }
  };

  // Normalize input references while preserving named node references
  if (normalizedNode.inputs) {
    Object.entries(normalizedNode.inputs).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length >= 2) {
        // Keep original ID for named nodes
        const sourceId = value[0];
        normalizedNode.inputs[key] = [
          typeof sourceId === 'string' && isNaN(sourceId) ? sourceId : sourceId.toString(),
          value[1]
        ];
      }
    });
  }

  return normalizedNode;
};

const pipelineToFlow = (pipeline, objectInfo, options = {}) => {
  const { 
    forceLayout = false, 
    pipelineType = null,
  } = options;

  if (!pipeline || !objectInfo) {
    console.warn("Missing pipeline or objectInfo data");
    return { nodes: [], edges: [] };
  }

  try {
    // Normalize pipeline structure while preserving named nodes
    const normalizedPipeline = {};
    Object.entries(pipeline).forEach(([id, node], index) => {
      const normalizedId = normalizeNodeId(id);
      normalizedPipeline[normalizedId] = normalizeNode(id, node, index);
    });

    // Convert nodes to flow format
    const nodes = Object.entries(normalizedPipeline).map(([id, node]) => {
      const nodeType = node.class_type;
      const nodeInfo = objectInfo[nodeType];

      if (!nodeInfo) {
        console.warn(`Unknown node type: ${nodeType}`);
        return null;
      }

      // Try to get cached position
      const cachedPosition = positionCache.get(id);

      return {
        id: id,
        type: 'custom',
        position: cachedPosition || node.position || { x: 0, y: 0 },
        data: {
          label: nodeType,
          type: nodeType,
          class_type: nodeType,
          IdNumber: node.IdNumber,
          nodeData: node,
          nodeObjectInfo: nodeInfo,
          fields: node.inputs || {},
        }
      };
    }).filter(Boolean);

    // Create edges while preserving node references
    const initialEdges = [];
    Object.entries(normalizedPipeline).forEach(([targetId, node]) => {
      if (!node.inputs) return;

      Object.entries(node.inputs).forEach(([inputName, input]) => {
        if (Array.isArray(input) && input.length >= 2) {
          const [sourceId, outputIndex] = input;
          
          initialEdges.push({
            id: `${sourceId}-${outputIndex}-${targetId}-${inputName}`,
            source: sourceId,
            target: targetId,
            sourceHandle: outputIndex.toString(),
            targetHandle: inputName,
            type: 'default'
          });
        }
      });
    });

    // Debug logging
    console.log('Pipeline conversion:', {
      originalPipeline: pipeline,
      normalizedPipeline,
      nodes,
      edges: initialEdges
    });

    // Apply layout
    currentPipelineType = pipelineType;  // Set current pipeline type before layout
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      nodes,
      initialEdges,
      'LR',
      forceLayout
    );

    return { nodes: layoutedNodes, edges: layoutedEdges };
  } catch (error) {
    console.error("Error converting pipeline:", error);
    return { nodes: [], edges: [] };
  }
};

const pipelineNodeToFlow = (id, node, objectInfo, index = 0) => {
  // ... existing code
};

export { 
  pipelineToFlow, 
  clearPositionCache,
  updateNodePosition,
  pipelineNodeToFlow
};
