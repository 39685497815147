import { Box, Tooltip, Tabs, TabList, TabPanels, Tab, TabPanel, Icon, Flex } from '@chakra-ui/react'
import { FaceDetailerOptions } from './FaceDetailerOptions';
import { RoopOptions } from './RoopOptions';
import { MainPassOptions } from './MainPassOptions';
import { WorkshopPropertiesToolbar } from './WorkshopPropertiesToolbar';
import { ControlNetOptions } from './ControlNetOptions';
import { IPAdapterOptions } from './IPAdapterOptions';
import { Img2ImgOptions } from './Img2ImgOptions';
import { UpscaleOptions } from './UpscaleOptions';
import { FaceRestoreOptions } from './FaceRestoreOptions';
import { PostProcessingOptions } from './PostProcessingOptions';

// Icons
import { LuScanFace } from 'react-icons/lu'
import { IoGameControllerOutline, IoApertureOutline } from 'react-icons/io5'
import { CgOptions } from 'react-icons/cg'
import { ImImages } from 'react-icons/im'
import { TbArrowsMaximize } from 'react-icons/tb'
import { PropBox } from './PropBox';
import { generateStarterJob } from 'utils/pieceUtils';
import { GiProcessor } from "react-icons/gi";

/**
 * Renders the WorkshopProperties component.
 *
 * @param {boolean} noTooltips - Flag indicating whether tooltips are disabled.
 * @param {Object} data - The data object.
 * @param {Object} defaults - The default values object.
 * @param {boolean} isLoaded - Flag indicating whether the component is loaded.
 * @param {boolean} isGenerating - Flag indicating whether the component is generating.
 * @param {Function} onChange - The onChange event handler.
 * @param {Function} generateHandler - The generateHandler event handler.
 * @param {Function} rollHandler - The rollHandler event handler.
 * @returns {JSX.Element} The rendered WorkshopProperties component.
 */
export function WorkshopProperties({
  architecture = "stable-diffusion",
  noTooltips,
  data,
  defaults,
  isLoaded = false,
  isGenerating = false,
  onChange = () => { console.warn("No onChange handler for WorkshopProperties.") },
  generateHandler = () => { console.warn("No generateHandler handler for WorkshopProperties.") },
  rollHandler = () => { console.warn("No rollHandler handler for WorkshopProperties.") },
}) {

  let starterJob = defaults ? defaults : generateStarterJob(true);

  return (
    <PropBox h={"100%"} collapsible={false} type="piece" value={data.params} label="Properties"
      onChange={(params) => onChange({ ...data, params })}
      isLoaded={isLoaded}
    >
      {/* Header */}
      <Flex direction="column" w={"full"} h={"100%"}>
        <Box flexShrink={0}>
          {/* Toolbar */}
          <WorkshopPropertiesToolbar
            architecture={architecture}
            isLoaded={isLoaded}
            data={data}
            onRoll={rollHandler}
            generateHandler={generateHandler}
            isGenerating={isGenerating}
            onChange={(d) => {
              onChange(d);
            }}
          />
        </Box>
        <Box overflowY={'auto'} height={"100%"}>
          {/* Vertical Properties Tabs */}
          <Tabs h={"100%"} orientation="vertical" isLazy lazyBehavior={'unmount'}>
            <Flex h={"100%"} w={"full"} direction={"row"}>
              <TabList>
                <Tab>
                  <Tooltip
                    placement="right"
                    isDisabled={noTooltips}
                    label="General"
                    hasArrow
                  >
                    <Icon boxSize={8} children={<CgOptions />} />
                  </Tooltip>
                </Tab>
                {data.params.mainPass && data.params.mainPass.img2img && data.params.mainPass.img2img.enabled && (
                  <Tab>
                    <Tooltip
                      placement="right"
                      isDisabled={noTooltips}
                      label="img2img Options"
                      hasArrow
                    >
                      <Icon boxSize={8} children={<ImImages />} />
                    </Tooltip>
                  </Tab>
                )}
                {data?.params?.upscaler?.enabled && (
                  <Tab>
                    <Tooltip
                      placement="right"
                      isDisabled={noTooltips}
                      label="Upscaler"
                      hasArrow
                    >
                      <Icon boxSize={8} children={<TbArrowsMaximize />} />
                    </Tooltip>
                  </Tab>
                )}
                {data.params.controlnet && data.params.controlnet.enabled && (
                  <Tab>
                    <Tooltip
                      placement="right"
                      isDisabled={noTooltips}
                      label="ControlNet"
                      hasArrow
                    >
                      <Icon
                        boxSize={8}
                        children={<IoGameControllerOutline />}
                      />
                    </Tooltip>
                  </Tab>
                )}
                {data.params.ipadapter && data.params.ipadapter.enabled && (
                  <Tab>
                    <Tooltip
                      placement="right"
                      isDisabled={noTooltips}
                      label="IP-Adapter"
                      hasArrow
                    >
                      <Icon boxSize={8} children={<IoApertureOutline />} />
                    </Tooltip>
                  </Tab>
                )}
                {((data.params.faceDetailer &&
                  data.params.faceDetailer.enabled) ||
                  ((data.params.roop && data.params.roop.enabled) ||
                  (data.params.faceRestore && data.params.faceRestore.enabled))) && (
                    <Tab>
                      <Tooltip
                        placement="right"
                        isDisabled={noTooltips}
                        label="Face Enhancements"
                        hasArrow
                      >
                        <Icon boxSize={8} children={<LuScanFace />} />
                      </Tooltip>
                    </Tab>
                  )}
                <Tab>
                  <Tooltip
                    placement="right"
                    isDisabled={noTooltips}
                    label="Post-processing"
                    hasArrow
                  >
                    <Icon boxSize={8} children={<GiProcessor />} />
                  </Tooltip>
                </Tab>
              </TabList>
              <Box h={'100%'} w={'100%'} overflowY={'auto'} >
                <TabPanels h={'100%'}>
                  {/* General */}
                  <TabPanel p={0} h={'100%'}>
                    <MainPassOptions architecture={architecture} value={data.params.mainPass} onChange={(v) => { onChange({ ...data, params: { ...data.params, mainPass: v }, }); }} />
                  </TabPanel>
                  {/* img2img */}
                  {data.params.mainPass.img2img && data.params.mainPass.img2img.enabled && (
                    <TabPanel p={0}>
                      <Img2ImgOptions
                        noTooltips={noTooltips}
                        denoise={data.params.mainPass.sampler.denoise}
                        value={data.params.mainPass.img2img}
                        onChange={(v) => {
                          onChange({
                            ...data,
                            params: {
                              ...data.params,
                              mainPass: {
                                ...data.params.mainPass,
                                img2img: v,
                              },
                            },
                          });
                        }}
                        onDenoiseChange={(v) => {
                          onChange({
                            ...data,
                            params: {
                              ...data.params,
                              mainPass: {
                                ...data.params.mainPass,
                                sampler: {
                                  ...data.params.mainPass.sampler,
                                  denoise: v,
                                },
                              },
                            },
                          });
                        }}
                      />
                    </TabPanel>
                  )}
                  {/* Upscaler */}
                  {data.params.upscaler && data.params.upscaler.enabled && (
                    <TabPanel p={0}>
                      {/* TODO: Change data.params.pipeline when re-doing main pass model props */}
                      {data.params.upscaler.enabled && (
                        <UpscaleOptions
                          mainSamplerPipeline={data.params.mainPass.pipeline}
                          noTooltips={noTooltips}
                          value={data.params.upscaler}
                          architecture={architecture}
                          onChange={(v) => {
                            onChange({
                              ...data,
                              params: { ...data.params, upscaler: v },
                            });
                          }}
                        />
                      )}
                    </TabPanel>
                  )}
                  {/* ControlNet */}
                  {data.params.controlnet && data.params.controlnet.enabled && (
                    <TabPanel p={0}>
                      <ControlNetOptions
                        value={data.params.controlnet}
                        onChange={(v) => {
                          onChange({
                            ...data,
                            params: { ...data.params, controlnet: v },
                          });
                        }}
                      />
                    </TabPanel>
                  )}
                  {/* IP-Adapter */}
                  {data.params.ipadapter && data.params.ipadapter.enabled && (
                    <TabPanel p={0}>
                      <IPAdapterOptions
                        value={data.params.ipadapter}
                        onChange={(v) => {
                          onChange({
                            ...data,
                            params: { ...data.params, ipadapter: v },
                          });
                        }}
                      />
                    </TabPanel>
                  )}
                  {/* Face Detailer */}
                  {((data.params.faceDetailer &&
                  data.params.faceDetailer.enabled) ||
                  ((data.params.roop && data.params.roop.enabled) ||
                  (data.params.faceRestore && data.params.faceRestore.enabled))) && (
                      <TabPanel p={0}>
                        <Flex direction={"column"} gap={2}>
                          {data.params.faceRestore.enabled && (
                            <FaceRestoreOptions
                              noTooltips={noTooltips}
                              value={data.params.faceRestore}
                              onChange={(v) => {
                                onChange({
                                  ...data,
                                  params: { ...data.params, faceRestore: v },
                                });
                              }}
                            />
                          )}
                          {/* TODO: Change data.params.pipeline when re-doing main pass model props */}
                          {data.params.faceDetailer && data.params.faceDetailer.enabled && (
                            <FaceDetailerOptions
                              architecture={architecture}
                              noTooltips={noTooltips}
                              mainSamplerPipeline={data.params.pipeline}
                              value={data.params.faceDetailer}
                              onChange={(v) => {
                                onChange({
                                  ...data,
                                  params: { ...data.params, faceDetailer: v },
                                });
                              }}
                            />
                          )}
                          {data.params.roop.enabled && (
                            <RoopOptions
                              noTooltips={noTooltips}
                              value={data.params.roop}
                              defaults={starterJob}
                              onChange={(v) => {
                                onChange({
                                  ...data,
                                  params: { ...data.params, roop: v },
                                });
                              }}
                            />
                          )}
                        </Flex>
                      </TabPanel>
                    )}
                  {/* Post-processing */}
                  <TabPanel p={0}>
                    <Flex direction={"column"} gap={2}>
                      <PostProcessingOptions value={data.params.postProcessing} onChange={v => {
                        onChange({
                          ...data,
                          params: {
                            ...data.params, postProcessing: v
                          }
                        })
                      }}
                      />
                    </Flex>
                  </TabPanel>
                </TabPanels>
              </Box>
            </Flex>
          </Tabs>
        </Box>
      </Flex>
    </PropBox>
  );
}