import { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { useAuth0 } from "@auth0/auth0-react";
import {
  Menu, MenuButton, MenuList, MenuItem, MenuDivider, 
  useColorModeValue, useColorMode, 
  Text, Badge, Tooltip, Box, Flex, Wrap, Button,
  ButtonGroup, Switch, 
  FormLabel
} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { CgLogOff } from 'react-icons/cg';
import { FaDiscord } from 'react-icons/fa';
import { MdOutlineMonitorHeart } from 'react-icons/md';
import { LoginButton } from './LoginButton';
import { Profile } from './Profile';
import { getEnvVariable } from 'utils/env';

/**
 * Renders the navigation component.
 * @returns {JSX.Element} The rendered navigation component.
 */
export function Nav() {
  const loginWithRedirect = useAuth0().loginWithRedirect;
  const { logout } = useAuth0();
  const { token, user, permissions } = useAuth()
  const { colorMode, toggleColorMode } = useColorMode();

  const navigate = useNavigate();
  
  const [pollId, setPollId] = useState(null)
  const [myInfo, setMyInfo] = useState({})

  const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)
  const REACT_APP_title = getEnvVariable("REACT_APP_title", process.env.REACT_APP_title)
  const REACT_APP_discord_link = getEnvVariable("REACT_APP_discord_link", process.env.REACT_APP_discord_link)

  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4} flexShrink={0}>
        <Flex alignItems={'center'}>
          <Text
            bgGradient="linear(to-l, #7928CA, #FF0080)"
            bgClip="text"
            fontSize="lg"
            fontWeight="bold"
            whiteSpace="nowrap"
            pl={{ base: 2, md: 0 }}
            display={{ base: 'none', md: 'flex' }}
          >
            <Link to="/">{REACT_APP_title}</Link>
          </Text>

          <Box p={{ base: 2, md: 4 }} pl={{ base: 4, md: 8 }} flex="1">
            {/* <CustomSearchBox /> */}
          </Box>

          <Button
            variant={'outline'}
            colorScheme={'blue'}
            onClick={() => {
              navigate(`/browse/recent`);
            }}
          >
            Images
          </Button>
          <Button
            variant={'outline'}
            colorScheme={'purple'}
            onClick={() => {
              navigate(`/train/dataset`);
            }}
          >
            Train
          </Button>
          {permissions.includes('any_models') && (
            <Button
              variant={'outline'}
              colorScheme={'orange'}
              onClick={() => navigate(`/models`)}
            >
              Models
            </Button>
          )}
          <ButtonGroup isAttached variant="outline" colorScheme="green">
            <Button
              style={{ margin: 0 }}
              onClick={() => {
                if (token) {
                  navigate(`/stable-diffusion`);
                } else {
                  loginWithRedirect(`/stable-diffusion`);
                }
              }}
            >
              SD
            </Button>
            <Button
              style={{ margin: 0 }}
              onClick={() => {
                if (token) {
                  navigate(`/flux`);
                } else {
                  loginWithRedirect(`/flux`);
                }
              }}
            >
              Flux
            </Button>
            {permissions.includes('neweditor') && <Button
              style={{ margin: 0 }}
              onClick={() => {
                if (token) {
                  navigate(`/neweditor`);
                } else {
                  loginWithRedirect(`/neweditor`);
                }
              }}
            >
              Pipeline
            </Button>}
          </ButtonGroup>
          <Flex marginLeft="auto" alignItems={'center'}>
            {!token && <LoginButton />}
            {token && (
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}
                  pr="2"
                  pl="1"
                >
                  <Profile myInfo={myInfo} />
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => navigate(`/gallery/${user.sub}`)}>
                    My Gallery
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate(`/gallery/${user.sub}/workspace`)}
                  >
                    My Workspace{' '}
                    {myInfo.reviews > 0 && (
                      <Badge ml={2} variant={'outline'} colorScheme={'green'}>
                        {myInfo.reviews}
                      </Badge>
                    )}
                  </MenuItem>
                  <MenuItem onClick={() => navigate(`/myprofile`)}>
                    My Account
                  </MenuItem>
                  {/* <MenuItem onClick={() => navigate(`/myinvites`)}>
                  My Invites {myInfo.invites > 0 && <Badge ml={2} variant={"outline"} colorScheme={"green"}>{myInfo.invites}</Badge>}
                </MenuItem> */}
                  <MenuDivider />
                  <MenuItem onClick={() => navigate(`/status`)}>
                    <MdOutlineMonitorHeart />
                    &nbsp;Status
                    {myInfo.queue > 0 && (
                      <Tooltip label={`Your queued jobs: ${myInfo.queue}`}>
                        <Badge ml={2} variant={'outline'} colorScheme={'blue'}>
                          {myInfo.queue}
                        </Badge>
                      </Tooltip>
                    )}
                    {myInfo.processing > 0 && (
                      <Tooltip
                        label={`Your processing jobs: ${myInfo.processing}`}
                      >
                        <Badge ml={2} variant={'outline'} colorScheme={'green'}>
                          {myInfo.processing}
                        </Badge>
                      </Tooltip>
                    )}
                    {myInfo.total_queue - myInfo.queue > 0 && (
                      <Tooltip
                        label={`Other user's jobs: ${
                          myInfo.total_queue - myInfo.queue
                        }`}
                      >
                        <Badge
                          ml={2}
                          variant={'outline'}
                          colorScheme={'orange'}
                        >
                          {myInfo.total_queue - myInfo.queue}
                        </Badge>
                      </Tooltip>
                    )}
                    {myInfo.failed > 0 && (
                      <Tooltip label={`Your failed jobs: ${myInfo.failed}`}>
                        <Badge ml={2} variant={'outline'} colorScheme={'red'}>
                          {myInfo.failed}
                        </Badge>
                      </Tooltip>
                    )}
                  </MenuItem>
                  <MenuDivider />
                  <Wrap>
                  <Switch
                    id='dark-mode-switch'
                    p={2}
                    colorScheme="blue"
                    isChecked={colorMode === 'dark'}
                    onChange={toggleColorMode}
                    aria-label={`Toggle ${
                      colorMode === 'light' ? 'Dark' : 'Light'
                    } Mode`}
                  /> 
                  <FormLabel htmlFor='dark-mode-switch'>
                  Dark Mode
                  </FormLabel>
                  </Wrap>
                  <MenuDivider />
                  <MenuItem onClick={() => window.open(REACT_APP_discord_link)}>
                    <FaDiscord />
                    &nbsp;Discord
                  </MenuItem>
                  <MenuItem
                    onClick={() => logout({ returnTo: window.location.origin })}
                  >
                    <CgLogOff />
                    &nbsp;Log Out
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
