// src/components/NewEditor/FlowView/FlowNode.js
import React, { useCallback } from 'react';
import { Handle, Position, useStore  } from 'reactflow';
import {
  Box,
  Text,
  IconButton,
  useColorModeValue,
  HStack,
  Badge,
  Tooltip
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

const FlowNode = ({ id, data, selected }) => {
  // Validate required data
  const isValidNode = Boolean(data?.nodeObjectInfo);
  if (!isValidNode) {
    console.warn(`Missing nodeObjectInfo for node ${id}`);
    
  }

  const inputs = data.nodeObjectInfo?.input?.required || {};
  const outputs = data.nodeObjectInfo?.output || [];

  const edges = useStore(state => state.edges);

  // Check connection for specific input handles
  const connectedInputHandles = edges.reduce((acc, edge) => {
    if (edge.target === id) {
      acc.add(edge.targetHandle); // Collect connected input handle IDs
    }
    return acc;
  }, new Set());

  // Check connection for specific output handles
  const connectedOutputHandles = edges.reduce((acc, edge) => {
    if (edge.source === id) {
      acc.add(edge.sourceHandle); // Collect connected output handle IDs
    }
    return acc;
  }, new Set());


  const bg = useColorModeValue('white', 'gray.800');

  // Calculate node dimensions
  const HANDLE_SPACING = 40;
  const HANDLE_SIZE = 12;
  const TOP_PADDING = 30;
  const MIN_NODE_HEIGHT = 120;
  
  // Calculate required height based on number of inputs/outputs
  const numConnections = Math.max(Object.keys(inputs).length, outputs.length);
  const nodeHeight = Math.max(MIN_NODE_HEIGHT, numConnections * HANDLE_SPACING + TOP_PADDING * 2);

  // Calculate handle positions starting from top
  const getHandlePosition = (index) => {
    return ((TOP_PADDING + (index * HANDLE_SPACING)) / nodeHeight) * 100;
  };

  // Add onDelete prop
  const onDelete = useCallback((e) => {
    e.preventDefault();  // Prevent default behavior
    e.stopPropagation(); // Stop event propagation
    
    console.log('FlowNode: Delete clicked for node:', id);
    if (data?.onDeleteNode) {
      data.onDeleteNode(id);
    }
  }, [id, data]);

  return (
    <Box
      className={`flow-node ${selected ? 'selected' : ''}`}
      bg={bg}
      borderWidth="1px"
      borderRadius="md"
      minW="250px"
      maxW="300px"
      position="relative"
      overflow="visible"
      boxShadow="lg"
    >
      {/* Header */}
      <Box
        p={2}
        borderBottomWidth="1px"
        bg={selected ? 'blue.900' : 'gray.800'}
        fontSize="13px"
        fontWeight="500"
        color="gray.200"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderTopRadius="md"
      >
        <HStack>
        <Badge colorScheme="blue">#{data.IdNumber || '?'}</Badge>
          <Tooltip
            placement="top-start"
            label={data.class_type}
            hasArrow
          >
            <Text
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              maxW="200px"
            >{id}</Text>
          </Tooltip>
          
        </HStack>
        <IconButton
          size="xs"
          icon={<DeleteIcon />}
          onClick={onDelete}
          variant="ghost"
          color="gray.400"
          _hover={{ color: "red.400", bg: "transparent" }}
          aria-label="Delete node"
        />
      </Box>

      {/* Content */}
      <Box 
        position="relative" 
        height={`${nodeHeight}px`}
        style={{ transition: 'height 0.2s ease' }}
      >
        {/* Input Handles */}
        <Box position="absolute" left="-12px" top="0" bottom="0" width="24px">
          {Object.entries(inputs).map(([key, type], index) => (
            <Box
              key={`input-${key}`}
              position="absolute"
              left="0"
              top={`${getHandlePosition(index)}%`}
              transform="translate(0, -50%)"
              zIndex={2}
              display="flex"
              alignItems="center"
            >
              <Handle
                type="target"
                position={Position.Left}
                id={key}
                style={{
                  width: `${HANDLE_SIZE}px`,
                  height: `${HANDLE_SIZE}px`,
                  background: connectedInputHandles.has(key) ? "#17D3B3" : "#F72585",
                  borderRadius: '50%',
                  border: '2px solid rgba(0, 0, 0, 0.3)',
                  transition: 'all 0.2s ease'
                }}
              />
              <Text
                fontSize="11px"
                color="gray.400"
                ml={5}
                maxW="120px"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {key}
              </Text>
            </Box>
          ))}
        </Box>

        {/* Output Handles */}
        <Box position="absolute" right="-12px" top="0" bottom="0" width="24px">
          {outputs.map((type, index) => (
            <Box
              key={`output-${index}`}
              position="absolute"
              right="0"
              top={`${getHandlePosition(index)}%`}
              transform="translate(0, -50%)"
              zIndex={2}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Text
                fontSize="11px"
                color="gray.400"
                mr={5}
                maxW="120px"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {type}
              </Text>
              <Handle
                type="source"
                position={Position.Right}
                id={`${index}`}
                style={{
                  width: `${HANDLE_SIZE}px`,
                  height: `${HANDLE_SIZE}px`,
                  background: connectedOutputHandles.has(`${index}`) ? "#17D3B3" : "#F72585",
                  borderRadius: '50%',
                  border: '2px solid rgba(0, 0, 0, 0.3)',
                  transition: 'all 0.2s ease'
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(FlowNode);
