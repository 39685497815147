import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, Text, VStack, HStack, Button, Flex, Badge,
  useColorModeValue, Wrap, WrapItem, Icon,
  Skeleton, SkeletonText
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { excludedNodes } from '../excludedNodes';
import { getFieldComponent } from './components/fields';
import { PropBox } from './components/PropBox';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';

// Helper function to format field names
const formatFieldName = (fieldName) => {
  return fieldName.replace(/_/g, ' ').toUpperCase();
};

const NodeView = ({ 
  data,
  objectInfo, 
  nodeDefinitions,
  onUpdateNode,
  onDeleteNode,
  onZoomToNode,
  onUpdateNodeId,
  isLoading
}) => {
  const bg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [selectedNode, setSelectedNode] = useState(null);

  // Debug logging for significant data changes
  const prevDataRef = useRef(null);
  useEffect(() => {
    // Only log if pipeline content has actually changed
    const pipelineStr = JSON.stringify(data?.pipeline || {});
    if (prevDataRef.current === pipelineStr) {
      return;
    }
    prevDataRef.current = pipelineStr;

  
  }, [data, nodeDefinitions, objectInfo]);

  // Add state to track temporary input values
  const [tempInputs, setTempInputs] = useState({});

  // Add handler for input changes
  const handleInputChange = (nodeId, fieldName, value) => {
    setTempInputs(prev => ({
      ...prev,
      [nodeId]: {
        ...prev[nodeId],
        [fieldName]: value
      }
    }));
  };

  // Add handler to commit changes after 2 minutes
  useEffect(() => {
    const timer = setTimeout(() => {
      // Only update if there are temporary changes
      if (Object.keys(tempInputs).length > 0) {
        Object.entries(tempInputs).forEach(([nodeId, fields]) => {
          const updatedNode = {
            ...data.pipeline[nodeId],
            inputs: {
              ...data.pipeline[nodeId].inputs,
              ...fields
            }
          };
          onUpdateNode(nodeId, updatedNode);
        });
        // Clear temp inputs after committing
        setTempInputs({});
      }
    }, 120000); // 2 minutes

    return () => clearTimeout(timer);
  }, [tempInputs, data.pipeline, onUpdateNode]);

  if (isLoading) {
    return (
      <Box p={4} bg={bg} borderRadius="md" borderWidth="1px" borderColor={borderColor}>
        <VStack spacing={4} align="stretch" w="100%">
          <Skeleton height="40px" />
          <SkeletonText mt="4" noOfLines={4} spacing="4" />
          <Skeleton height="60px" />
        </VStack>
      </Box>
    );
  }

  if (!data?.pipeline) {
    console.debug("No pipeline data available");
    return null;
  }

  // Use either nodeDefinitions or objectInfo for node type validation
  const definitions = nodeDefinitions || objectInfo;
  if (!definitions) {
    console.debug("No node definitions available");
    return null;
  }

  // Filter out null or undefined nodes and excluded nodes
  const validNodes = Object.entries(data.pipeline || {})
    .sort((a, b) => (a[1].IdNumber || 0) - (b[1].IdNumber || 0)) // Sort by IdNumber
    .filter(([id, node]) => {
    if (!node || !node.class_type) {
      console.debug(`Invalid node data for ${id}:`, node);
      return false;
    }
    
    // Try case-insensitive lookup
    const nodeType = node.class_type;
    const definition = definitions[nodeType] || 
                      definitions[nodeType.toLowerCase()] || 
                      Object.keys(definitions).find(k => k.toLowerCase() === nodeType.toLowerCase());
    
    if (!definition) {
      console.debug(`Unknown node type ${nodeType} for node ${id}. Available types:`, 
        Object.keys(definitions).slice(0, 5).join(", ") + "...");
      return false;
    }
    
    if (excludedNodes.includes(nodeType)) {
      console.debug(`Excluded node type ${nodeType} for node ${id}`);
      return false;
    }
    
    return true;
  });

  if (validNodes.length === 0) {
    console.debug("No valid nodes found in pipeline.", {
      totalNodes: Object.keys(data.pipeline || {}).length,
      definitions: Object.keys(definitions).length
    });
    return null;
  }

  // Helper function to format field names
  const formatFieldName = (field) => {
    // Check if it's a safetensors file
    if (field.endsWith('.safetensors') || field.endsWith('.sft')) {
      const ext = field.slice(field.lastIndexOf('.'));
      const filename = field.slice(0, -ext.length);
      
      // If it's a hash (64 chars hex), show a shorter version
      if (filename.length === 64 && isHexString(filename)) {
        // Convert hash to human readable format if available
        const humanName = window.hashNames?.[filename];
        if (humanName) {
          return `${humanName}${ext}`;
        }
        // Fallback to shortened hash if no human name available
        return `${filename.slice(0, 8)}...${filename.slice(-8)}${ext}`;
      }
    }
    
    // For other fields, if it's a hash, show human readable or shortened version
    if (field.length === 64 && isHexString(field)) {
      const humanName = window.hashNames?.[field];
      if (humanName) {
        return humanName;
      }
      return `${field.slice(0, 8)}...${field.slice(-8)}`;
    }
    
    return field;
  };

  const getFieldTypeAndOptions = (nodeType, field) => {
    if (!nodeDefinitions?.[nodeType]) return { type: 'STRING' };
    
    const nodeInfo = nodeDefinitions[nodeType];
    const input = nodeInfo.input || {};
    
    // Check if field exists in required or optional inputs
    const fieldInfo = input.required?.[field] || input.optional?.[field];
    
    if (!fieldInfo) return { type: 'STRING' };

    // Handle array-style field definitions from ComfyUI
    if (Array.isArray(fieldInfo)) {
      const [type, config] = fieldInfo;
      
      // If first element is array, it's a SELECT with choices
      if (Array.isArray(type)) {
        return { type: 'SELECT', options: type };
      }

      // Handle special field types
      if (type === 'MODEL' || type === 'VAE' || type === 'CLIP' || 
          type === 'CONTROL_NET' || type === 'LORA' || type === 'EMBEDDING') {
        return {
          type: type,
          modelType: config?.modelType || [type],
          tooltip: config?.tooltip || ''
        };
      }

      return {
        type: type,
        min: config?.min,
        max: config?.max,
        step: config?.step,
        default: config?.default,
        options: config?.choices || [],
        tooltip: config?.tooltip || ''
      };
    }

    return { type: 'STRING' };
  };

  const handleNodeFieldChange = (nodeId, field, value) => {
    const node = data?.pipeline?.[nodeId];
    if (!node?.class_type) return;

    const { type } = getFieldTypeAndOptions(node.class_type, field);
    
    // Process value based on type
    let processedValue = value;
    if (type === 'INT') processedValue = parseInt(value) || 0;
    if (type === 'FLOAT') processedValue = parseFloat(value) || 0.0;
    if (type === 'BOOLEAN') processedValue = Boolean(value);
    if (type === 'MODEL' || type === 'VAE' || type === 'CLIP' || 
        type === 'CONTROL_NET' || type === 'LORA' || type === 'EMBEDDING') {
      processedValue = value ? value.toString() : null;
    }

    const updatedNode = {
      ...node,
      inputs: {
        ...node.inputs,
        [field]: processedValue
      }
    };

    onUpdateNode?.(nodeId, updatedNode);
  };

  const handleDeleteNode = (nodeId) => {
    if (onDeleteNode && nodeId) {
      onDeleteNode(nodeId);
    }
  };

  const renderNodeField = (nodeId, field, value, nodeType) => {
    if (!nodeType || !nodeDefinitions?.[nodeType]) return null;
    
    const fieldInfo = getFieldTypeAndOptions(nodeType, field);

    // Handle non-array cases first
    if (!Array.isArray(value)) {
      const FieldComponent = getFieldComponent(nodeType, field, fieldInfo.type);
      if (!FieldComponent) return null;
      
      return (
        <Box key={field}>
          <FieldComponent
            label={formatFieldName(field)}
            value={value}
            onChange={(newValue) => handleNodeFieldChange(nodeId, field, newValue)}
            {...fieldInfo}
          />
        </Box>
      );
    }
    
   
  };

  const isHexString = (str) => /^[0-9a-fA-F]+$/.test(str);

  const getConnectionStatus = (field, value) => {
    if (!value) return false;
    if (Array.isArray(value)) {
      const [sourceNode, outputIndex] = value;
      return Boolean(sourceNode) && outputIndex !== undefined;
    }
    return false;
  };

  const NodeHeader = ({ node, isExpanded, onToggle }) => (
    <Flex 
      p={2} 
      bg="gray.700" 
      borderTopRadius="md" 
      justifyContent="space-between" 
      alignItems="center"
      cursor="pointer"
      onClick={onToggle}
    >
      <HStack spacing={2}>
        <Text>{node?.class_type}</Text>
      </HStack>
      <Icon
        as={isExpanded ? FiChevronUp : FiChevronDown}
        transition="transform 0.2s"
        transform={isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)'}
      />
    </Flex>
  );

  return (
    <VStack spacing={4} align="stretch" w="100%">
      {validNodes.map(([nodeId, node]) => {
        if (!node?.class_type) return null;
        
        return (
          <PropBox
            key={nodeId}
            label={<NodeHeader node={node} />}
            id={nodeId}
            IdNumber={node.IdNumber}
            value={tempInputs[nodeId]?.[node.class_type] ?? node.inputs[node.class_type]}
            collapsible={true}
            defaultCollapsed={true}
            node={node}
            data={data}
            onZoomToNode={onZoomToNode}
            onUpdateNodeId={onUpdateNodeId}
            extras={[{
              icon: <DeleteIcon />,
              label: "Delete Node",
              onClick: (e) => {
                e.stopPropagation();
                handleDeleteNode(nodeId);
              }
            }]}
            onChange={(value) => handleInputChange(nodeId, node.class_type, value)}
          >
            <VStack align="stretch" spacing={3} >
              <Box>
                {Object.entries(node.inputs || {}).map(([field, value]) => 
                  renderNodeField(nodeId, field, value, node.class_type)
                )}
              </Box>

              {/* Show connections in ComfyUI style */}
              <Box p={2} border={"1px solid"} borderColor={"gray.700"} borderRadius="md">
                <VStack align="stretch" spacing={1}>
                  <Text fontSize="xs" color="gray.500">Inputs:</Text>
                  <Wrap spacing={1}>
                    {Object.entries(node.inputs || {}).map(([field, value]) => {
                      const isConnected = getConnectionStatus(field, value);
                      return (
                        <WrapItem key={field}>
                          <Badge 
                            colorScheme={isConnected ? "green" : "pink"}
                            variant="solid"
                            size="xs"
                            borderRadius={"full"}
                            px={2}
                            textTransform="uppercase"
                          >
                            {formatFieldName(field)}
                          </Badge>
                        </WrapItem>
                      );
                    })}
                  </Wrap>

                  {/* Show outputs */}
                  {nodeDefinitions[node.class_type]?.output && (
                    <>
                      <Text fontSize="xs" color="gray.500" mt={2}>Outputs:</Text>
                      <Wrap spacing={1}>
                        {Object.keys(nodeDefinitions[node.class_type].output).map((outputIndex) => {
                          const outputName = nodeDefinitions[node.class_type].output_name[outputIndex];
                          const isConnected = Object.values(data.pipeline || {}).some(otherNode => 
                            Object.values(otherNode.inputs || {}).some(input => 
                              Array.isArray(input) && input[0] === nodeId && input[1] === parseInt(outputIndex)
                            )
                          );
                          return (
                            <WrapItem key={outputIndex}>
                              <Badge
                                colorScheme={isConnected ? "green" : "pink"}
                                variant="solid"
                                size="xs"
                                borderRadius={"full"}
                                px={2}
                                textTransform="uppercase"
                              >
                                {outputName}
                              </Badge>
                            </WrapItem>
                          );
                        })}
                      </Wrap>
                    </>
                  )}
                </VStack>
              </Box>
            </VStack>
          </PropBox>
        );
      })}
    </VStack>
  );
};

export default NodeView;