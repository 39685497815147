import {
    NumberIncrementStepper, NumberDecrementStepper, NumberInput, NumberInputStepper, NumberInputField,
    FormLabel, Select, Switch, Tooltip, Flex,
} from '@chakra-ui/react';
import { PromptOptions } from './PromptOptions';
import { SizeOptions } from './SizeOptions';
import { SamplerOptions } from './SamplerOptions'
import { ModelOptions } from './ModelOptions';
import { PropBox } from './PropBox';

/**
 * Renders the MainPassOptions component.
 * @param {Object} props - The component props.
 * @param {Object} props.value - The current value of the component.
 * @param {Function} props.onChange - The function to handle value changes.
 * @returns {JSX.Element} The rendered main pass options component.
 */
export const MainPassOptions = ({
    value,
    architecture = "stable-diffusion",
    onChange=()=>{console.warn("MainPassOptions.onChange not implemented.")}
}) => {

    let pipeline = value.model.pipeline

    let resolutions = [
        { w: 512, h: 512, d: "1:1 (Square)" },
        { w: 576, h: 448, d: "4:3 (Standard Monitor)" },
        { w: 608, h: 416, d: "3:2 (Classic Film)" },
        { w: 672, h: 384, d: "16:9 (HD Video)" },
        { w: 768, h: 320, d: "21:9 (Cinemascope)" },
        { w: 448, h: 576, d: "3:4 (Standard Monitor - Vertical)" },
        { w: 416, h: 608, d: "2:3 (Classic Film - Vertical)" },
        { w: 384, h: 672, d: "9:16 (HD Video - Vertical)" },
        { w: 320, h: 768, d: "9:21 (Cinemascope - Vertical)" }
    ]

    // sd-1.5 is 512x512, so we need to double the resolution for non-SD-1.5 models
    // Works for SD 3 also
    if (pipeline.type !== "sd-1.5") {
        resolutions.forEach(r => {
            r.w = r.w * 2
            r.h = r.h * 2
        })
    }

    return <PropBox label="Main Pass" type="mainPass" value={value} onChange={v => onChange(v)}>
        {/* img2img */}
        <Flex>
            <Tooltip hasArrow label="img2img" openDelay={250}>
                <FormLabel htmlFor="img2img">Enable img2img</FormLabel>
            </Tooltip>
            <Switch
                id="img2img"
                isChecked={value.img2img.enabled}
                onChange={e => onChange({ ...value, img2img: { ...value.img2img, enabled: e.target.checked } })}
            />
        </Flex>
        {!value.img2img.enabled && <>
            {/* Quick Resolution Picker */}
            <Flex>
                <Tooltip hasArrow label="Recommended Resolutions - These are the recommended resolutions.  You can customize the width and height beyond these aspect ratios if you wish, however going too far may lead to doubles of your subject." openDelay={250}>
                    <FormLabel>Resolution Picker</FormLabel>
                </Tooltip>
                <Select onChange={e => {
                    let r = resolutions[e.currentTarget.value]
                    onChange({ ...value, "width": r.w, "height": r.h })
                }}>
                    {resolutions.map((resolution, i) => {
                        return <option value={i} key={`${resolution.w}x${resolution.h}`}>{resolution.w}x{resolution.h} ({resolution.d})</option>
                    })}
                </Select>
            </Flex>
            {/* Width/Height Controls */}
            <SizeOptions value={value} onChange={v => { onChange({ ...value, width: v.width, height:v.height }) }} />
        </>}

        {/* offset_noise */}
        <Flex mr={2}>
            <Tooltip hasArrow label="MSG for your image, try a value of 1.0 and adjust to taste." openDelay={250}>
                <FormLabel whiteSpace={"nowrap"} htmlFor="offset_noise">Offset Noise</FormLabel>
            </Tooltip>
            <NumberInput
                id="offset_noise"
                value={value.offset_noise}
                min={0.0}
                max={1.5}
                step={0.25}
                clampValueOnBlur={true}
                onChange={v => { onChange({ ...value, "offset_noise": v }) }}
            >
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </Flex>

        {/* prompts */}
        <PromptOptions
            pipeline={value.model.pipeline}
            value={value.prompts}
            onChange={v => { onChange({ ...value, prompts: v }) }} />

        {/* Sampler */}
        <SamplerOptions pipeline={value.model.pipeline} value={value.sampler} onChange={v => { onChange({ ...value, sampler: v }) }} architecture={architecture}/>

        {/* Model */}
        <ModelOptions architecture={architecture} value={value.model} onChange={v => { onChange({ ...value, model: v }) }} />
    </PropBox>
}