import { useNavigate } from 'react-router-dom';
import JsonView from '@uiw/react-json-view';
import { nordTheme } from '@uiw/react-json-view/nord';
import { TiFlowChildren } from 'react-icons/ti'
import { FiCopy, FiEdit } from 'react-icons/fi'
import {
    Flex, Text, Button, Image as ChakraImage, Code, Progress, Box, Heading, Badge,
    Tabs, TabList, TabPanels, Tab, TabPanel, Tooltip, useToast, IconButton
} from '@chakra-ui/react'
import { WorkshopPreviewToolbar } from './WorkshopPreviewToolbar';
import { PropBox } from './PropBox';
import { getEnvVariable } from 'utils/env';

/**
 * Renders a preview of a workshop.
 *
 * @param {Object} data - The workshop data.
 * @param {boolean} [isLoaded=false] - Indicates if the workshop data is loaded.
 * @param {Function} [setPrivateHandler] - The handler for setting the workshop as private.
 * @param {Function} [publishHandler] - The handler for publishing the workshop.
 * @param {Function} [personalPublishHandler] - The handler for personal publishing the workshop.
 * @param {Function} [deleteHandler] - The handler for deleting the workshop.
 * @param {Function} [retryHandler] - The handler for retrying the workshop.
 * @param {Function} [cancelHandler] - The handler for canceling the workshop.
 * @param {Function} [onChange] - The handler for workshop data changes.
 * @param {Function} [onImageClick] - The handler for clicking on the workshop image.
 * @param {Function} [onParent] - The handler for opening the parent workshop.
 * @returns {JSX.Element} The rendered workshop preview.
 */
export function WorkshopPreview({ 
    data,
    isLoaded = false,
    architecture = "stable-diffusion",
    setPrivateHandler = () => {console.warn("No setPrivateHandler for WorkshopPreview.")},
    publishHandler = () => {console.warn("No publishHandler for WorkshopPreview.")},
    personalPublishHandler = () => {console.warn("No personalPublishHandler for WorkshopPreview.")},
    deleteHandler = () => {console.warn("No deleteHandler for WorkshopPreview.")},
    onChange = () => {console.warn("No onChange handler for WorkshopPreview.")},
    onImageClick = () => {console.warn("No onImageClick handler for WorkshopPreview.")},
    onParent = () => {console.warn("No onParent handler for WorkshopPreview.")},
}) {
    const navigate = useNavigate();
    const toast = useToast()
    const REACT_APP_images_url = getEnvVariable("REACT_APP_images_url", process.env.REACT_APP_images_url)

    const supplementalExists = () => {
        if (data.location && data.location.supplemental && Object.keys(data.location.supplemental).length > 0) return true
        return false
    }
    let tabs = [
        <Tab>Image</Tab>,
        (supplementalExists() && <Tab>Debug ({Object.keys(data.location.supplemental).length})</Tab>),
        <Tab>Logs</Tab>
    ]
    let tabPanels = [
        <TabPanel>
            {data.location && <ChakraImage style={{ cursor: (onImageClick !== undefined ? "pointer" : "arrow") }} src={`${REACT_APP_images_url}/${data.location.jpg}`} alt={data.params.prompt} onClick={e => {
                onImageClick(data)
            }} />}
        </TabPanel>,
        (supplementalExists() && <TabPanel p={0}>
            {Object.keys(data.location.supplemental).map((node_id) => {
                let imgurl = data.location.supplemental[node_id]["png"]
                return <>
                    <Badge variant={"outline"}>{node_id}</Badge>
                    <ChakraImage src={`${REACT_APP_images_url}/${imgurl}`} alt={node_id} pb={5} />
                </>
            })}
        </TabPanel>),
        <TabPanel>
            {data.log && <>
                <Heading size="md">Log:</Heading>
                <Code fontSize={"xs"} maxH={400} style={{ overflow: "auto" }} dangerouslySetInnerHTML={{ __html: data.log.replace(/\n/g, "<br/>") }}></Code>
            </>}
            {data.errlog && <>
                <Heading size="md">Error Log:</Heading>
                <Code fontSize={"xs"} maxH={400} style={{ overflow: "auto" }} dangerouslySetInnerHTML={{ __html: data.errlog.replace(/\n/g, "<br/>") }}></Code>
            </>}
        </TabPanel>
    ]
    if(data.pipeline) {
        // vscodeTheme.overflow = "auto"
        // vscodeTheme.whiteSpace = "nowrap"
        tabs.push(<Tab>Pipeline</Tab>)
        tabPanels.push(<TabPanel>
            <Tooltip hasArrow label="Edit Pipeline">
                <IconButton icon={<FiEdit />} onClick={e => { navigate(`/neweditor/${data._id}`)} } variant={"ghost"} />
            </Tooltip>
            <Tooltip hasArrow label="Copy to clipboard">
                <IconButton icon={<FiCopy />} onClick={e => {
                    navigator.clipboard.writeText(JSON.stringify(data.pipeline, null, 2)).then(
                        () => {
                            toast({ title: `Copied to clipboard.` })
                        }, () => {
                            /* clipboard write failed */
                        }
                    );
                } } variant={"ghost"} />
            </Tooltip>
            <JsonView 
                // indentWidth={20}
                // shortenTextAfterLength={0}
                value={data.pipeline} 
                style={nordTheme}
                enableClipboard={false}
                collapsed={false}
                displayDataTypes={false}
                displayObjectSize={false}
            />
        </TabPanel>)
    }
    if(data.caption) {
        tabs.push(<Tab>Captions</Tab>)
        tabPanels.push(<TabPanel>
            <Heading size="md">Tags:</Heading>
            <Text>{data.caption.llava.response}</Text>
            <Heading size="md">Description:</Heading>
            <Text>{data.caption.llava_describe.response}</Text>
        </TabPanel>)
    }
    let imgView = <Tabs>
        <TabList>{tabs}</TabList>
        <TabPanels>{tabPanels}</TabPanels>
    </Tabs >

    return <PropBox h={"100%"} label={data.status!=="new"?"Results":"Getting Started"} clipboard={false} isLoaded={isLoaded} collapsible={false}>
        {/* Header */}
        <Flex direction="column" h={'100%'} w={"full"}>
            <Box flexShrink={0}>
                <WorkshopPreviewToolbar data={data} onChange={d => { onChange(d) }} isLoaded={isLoaded}
                    onPublish={publishHandler} onPersonalPublish={personalPublishHandler} onDelete={deleteHandler}
                    onSetPrivate={setPrivateHandler} />
            </Box>
            {data && <Box overflowY={"auto"} height={'100%'} 
            // maxHeight={'calc(100vh - 300px)'}
            >
                {data.status === "new" && <Box>
                    <ul>
                        <li>- In the Properties panel, set the parameters as you wish, and click "Generate" to create a new image.</li>
                        <li>- You can also open existing images in the Browse panel to load them into the workshop.</li>
                    </ul>
                </Box>}
                {data.status !=="new" && imgView}
                {data.status === "failed" && <Box>
                    <Badge variant={"outline"}>{data.agent}</Badge>
                    <Heading size="md">Traceback:</Heading>
                    {data.traceback && <Code>{data.traceback.replace("\n", "<br/>")}</Code>}
                    {data.log && <>
                        <Heading size="md">Log:</Heading>
                        <Code>{data.log.replace("\n", "<br/>")}</Code>
                    </>}
                    {data.errlog && <>
                        <Heading size="md">Error Log:</Heading>
                        <Code>{data.errlog.replace("\n", "<br/>")}</Code>
                    </>}
                </Box>}
                {data.status === "processing" && <Box>
                    <Heading size="md">Progress:</Heading>
                    {(data.stats && data.stats.progress) ? <>
                        <Progress rounded={"full"} colorScheme={"green"} value={(data.stats.progress.progress * 100)} />
                        <Text fontSize={"xs"}>{data.stats.task} ({data.stats.progress.value}/{data.stats.progress.max})</Text>
                    </> : <Badge>Starting...</Badge>}
                </Box>
                }
                {data.status!=="new" && <Box pb={1}>
                    {data._id && <Badge fontSize={"xs"} cursor={"pointer"} onClick={e => {
                        navigator.clipboard.writeText(data._id).then(
                            () => {
                                toast({ title: `Copied to clipboard.` })
                            }, () => {
                                /* clipboard write failed */
                            }
                        );
                    }}>Copy ID</Badge>}
                    <Badge variant={"outline"}>{data.status}</Badge>
      
                    <Badge variant={"outline"}>{data?.agent_id}</Badge>
                    {/* <Badge variant={"outline"}>{sourceToName(data.source)}</Badge> */}
                    {data?.params?.parent_id && data?.params?.parent_id !== "new" && <Tooltip hasArrow label={`This is where the piece was last modified from.  (${data?.params?.parent_id})`} openDelay={250}>
                        <Button size="xs" onClick={e => { onParent(data?.params?.parent_id)}}><TiFlowChildren />Open Parent</Button>
                    </Tooltip>}
                    
                </Box>}
            </Box>}
        </Flex>
    </PropBox>
}