import React, { useState } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { Box, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { GalleryFeed } from './Components/GalleryFeed';
import { QueueFeed } from 'components/shared/Feed/QueueFeed';

export const GalleryView = ({ objectInfo, data, onCreate, onSelect }) => {
  const { user } = useAuth();
  const [selectedTab, setSelectedTab] = useState(0);

  const retryHandler = (piece) => {
    console.log('Retry', piece);
  };

  const cancelHandler = (piece) => {
    console.log('Cancel', piece);
  };

  const refresh = () => {
    console.log('Refresh');
  };

  return (
    <Box h="100%" overflow="hidden">
      <Tabs 
        index={selectedTab} 
        onChange={setSelectedTab}
        h="100%"
        display="flex"
        flexDirection="column"
        variant="enclosed"
      >
        <TabList>
          <Tab>Workspace</Tab>
          <Tab>Published</Tab>
          <Tab>Personal</Tab>
          <Tab>Queued</Tab>
        </TabList>

        <TabPanels flex="1" overflow="auto">
          <TabPanel h="100%" p={0}>
            <Box display={'block'} h={"calc(100dvh - 280px)"} w={"full"}>
              <GalleryFeed 
                h={"100vh"} 
                mode="review" 
                path={`/v3/my/reviews/${user?.sub}/desc`} 
                source="work" 
                method="continuation" 
                onCreate={onCreate}
                onClick={(piece) => onCreate(piece)} 
              />
            </Box>
          </TabPanel>
          <TabPanel h="100%" p={0}>
            <Box display={'block'} h={"calc(100dvh - 280px)"} w={"full"}>
              <GalleryFeed 
                path={`/v3/my/pieces/${user?.sub}/desc`} 
                source="pieces"
                method="continuation" 
                onCreate={onCreate}
                onClick={(piece) => onCreate(piece)}
              /> 
            </Box>
          </TabPanel>
          <TabPanel h="100%" p={0}>
            <Box display={'block'} h={"calc(100dvh - 280px)"} w={"full"}>
              <GalleryFeed 
                path={`/v3/my/personal/${user?.sub}/desc`} 
                source="pieces" 
                method="continuation" 
                onCreate={onCreate}
                onClick={(piece) => onCreate(piece)}
                filters={{ 
                  source: "personal_pieces"
                }}
                mode="personal"
              />       
            </Box>
          </TabPanel>
          <TabPanel h="100%" p={0}>
            <Box display={'block'} h={"calc(100dvh - 280px)"} w={"full"}>
              <QueueFeed 
                retryHandler={retryHandler} 
                cancelHandler={cancelHandler} 
                refresh={refresh} 
                onSelect={piece => onCreate(piece)}
              />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default GalleryView;