import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useAuth } from 'contexts/AuthContext'
import { Spinner, Center, Box } from '@chakra-ui/react'
// import { useNavigate} from 'react-router-dom'

import { GalleryPreview } from './GalleryPreview'
import { InfinityLightbox } from 'components/shared/Feed/InfinityLightbox'
import { getEnvVariable } from 'utils/env'

/**
 * Renders an infinity feed component.
 *
 * @param {string} path - The API path for fetching items.
 * @param {string} method - The method for fetching items.
 * @param {string} mode - The mode of the infinity feed.
 * @param {string} [source="pieces"] - The source of the items.
 * @returns {JSX.Element} The rendered InfinityFeed component.
 */
export function GalleryFeed({
  onClick,
  onCreate,
  path,
  method,
  mode,
  source = "pieces",
  filters = {}
}) {
  const { isAuthenticated, token } = useAuth()
  const [items, setItems] = useState([])
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const [selectedPiece, setSelectedPiece] = useState(null)
  const [lastPiece, setLastPiece] = useState(null)
  const [apiPath, setApiPath] = useState("")
  const [activeImages, setActiveImages] = useState({})
  const [fetching, setFetching] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const sentinelRef = useRef(null)
  const infinityGalleryRef = useRef(null)

  const REACT_APP_api_url = getEnvVariable("REACT_APP_api_url", process.env.REACT_APP_api_url)

  let apiURL = `${REACT_APP_api_url}${path}`;
  if (method === "random") {
    apiURL = `${REACT_APP_api_url}${path}`;
  } else if (method === "continuation") {
    if (lastPiece?._id) {
      apiURL = `${REACT_APP_api_url}${path}/50/${lastPiece._id}`;
    } else {
      apiURL = `${REACT_APP_api_url}${path}/50`;
    }
  }

  const fetchNewItems = useCallback(() => {
    let headers = {
      "Content-Type": "application/json"
    };
    if (isAuthenticated) {
      headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      };
    }
    console.log(`Fetching from ${apiURL}`, { filters });
    return fetch(apiURL, { 
      method: "POST", 
      headers, 
      body: JSON.stringify({ filters }) 
    })
      .then(response => response.json())
      .then(data => data);
  }, [apiURL, isAuthenticated, token, filters]);

  const openLightbox = (piece) => {
    setIsLightboxOpen(true);
    setSelectedPiece(piece);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
    setSelectedPiece(null);
  };

  const handleMoved = (id) => {
    setItems(prevItems => {
      const newItems = [...prevItems];
      const index = newItems.findIndex(item => item._id === id);
      if (index !== -1) {
        newItems.splice(index, 1);
        if (selectedPiece && selectedPiece._id === id) {
          setSelectedPiece(newItems[index] || newItems[index - 1]);
        }
      }
      return newItems;
    });
  };

  // Reset items when path changes
  useEffect(() => {
    if (apiPath !== path) {
      setApiPath(path);
      setItems([]);
      setHasMore(true);
      setLastPiece(null);
    }
  }, [apiPath, path]);

  // Image intersection observer
  useEffect(() => {
    const imageNodes = document.querySelectorAll('.infinitygallery > div');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const imgId = entry.target.dataset.id;
        if (entry.isIntersecting) {
          setActiveImages(prev => ({ ...prev, [imgId]: true }));
        } else {
          setActiveImages(prev => {
            const next = { ...prev };
            delete next[imgId];
            return next;
          });
        }
      });
    });
    
    imageNodes.forEach(node => observer.observe(node));
    return () => observer.disconnect();
  }, [items]);

  const fetchMoreItems = useCallback(() => {
    if (fetching || !hasMore) return Promise.resolve();
    
    setFetching(true);
    return fetchNewItems()
      .then(newItems => {
        if (!Array.isArray(newItems) || newItems.length === 0) {
          setHasMore(false);
          return;
        }
        
        setItems(prevItems => {
          const uniqueNewItems = newItems.filter(
            newItem => !prevItems.some(prevItem => prevItem._id === newItem._id)
          );
          return [...prevItems, ...uniqueNewItems];
        });
        
        setLastPiece(newItems[newItems.length - 1]);
      })
      .catch(err => {
        console.error('Error in fetchMoreItems:', err);
        setHasMore(false);
      })
      .finally(() => {
        setFetching(false);
      });
  }, [fetchNewItems, fetching, hasMore]);

  // Infinite scroll observer
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !fetching && hasMore) {
          fetchMoreItems();
        }
      });
    });
    
    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }
    
    return () => observer.disconnect();
  }, [fetchMoreItems, fetching, hasMore]);

  return (
    <>
      {isLightboxOpen && (
        <InfinityLightbox
          selectedPiece={selectedPiece}
          source={source}
          items={items}
          mode={mode}
          onMoved={handleMoved}
          onEnd={fetchMoreItems}
          onCloseHandler={closeLightbox}
          onSelectHandler={(item) =>
            setSelectedPiece(JSON.parse(JSON.stringify(item)))
          }
          onCreate={onCreate}
        />
      )}

      <Box className="infinitygallery" ref={infinityGalleryRef}>
        {items.map((piece) => (
          <div
            key={piece._id}
            data-id={piece._id}
            style={{ height: '250px' }}
            className={`square fade-in ${
              activeImages[piece._id] ? 'visible' : 'unset'
            }`}
          >
            {activeImages[piece._id] && source !== 'models' && (
              <GalleryPreview
                mode={mode}
                piece={piece}
                source={source}
                onMoved={handleMoved}
                onClick={onClick}
                onCreate={() => onCreate(piece)}
                openLightbox={openLightbox}
              />
            )}
            {!activeImages[piece._id] && (
              <Box pos="relative" borderRadius="lg" bg="blue.800" />
            )}
          </div>
        ))}
      </Box>

      <Box ref={sentinelRef} style={{ width: '100%' }}>
        <Center p={5}>{fetching && <Spinner label="Loading more..." />}</Center>
        {!hasMore && <Center p={5}>No more items</Center>}
      </Box>
    </>
  );
}

export default GalleryFeed
