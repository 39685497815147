import React, { useState, useEffect } from 'react';

import {
  Box, Popover, PopoverTrigger, IconButton, PopoverContent, PopoverHeader, PopoverArrow, PopoverBody,
  Button, ButtonGroup, Slider, SliderTrack, SliderFilledTrack, SliderThumb,
  NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
  Tooltip, Flex
} from '@chakra-ui/react';

import { AiOutlinePlayCircle } from 'react-icons/ai'
import { VscDebugAlt, VscDebugStart } from "react-icons/vsc";
import { BiShow, BiHide } from 'react-icons/bi'
import { BsDice3 } from 'react-icons/bs'
import { FaRandom } from 'react-icons/fa'

/**
 * GeneratePopover component for NewEditor.
 * 
 * @component
 * @param {boolean} isGenerating - Flag indicating if generation is in progress.
 * @param {Object} data - The current editor data.
 * @param {Function} onChange - Handler for data changes.
 * @param {Function} onClick - Handler for generate button clicks.
 * @param {string} parent_id - ID of the parent job.
 * @returns {JSX.Element} GeneratePopover component.
 */
export const GeneratePopover = ({
  isGenerating = false,
  data = {},
  onChange = () => console.warn("No GeneratePopover onChange handler."),
  onClick = console.warn("No GeneratePopover onClick handler."),
  parent_id = "new"
}) => {
  const p = localStorage.getItem("private") === "true";
  const [isPrivate, setIsPrivate] = useState(p);

  const handleGenerate = (debug = false) => {
    const options = {
      batchSize : 1,
      priority : "medium",
      isPrivate,
      debug : false,
      parent_id
    };
    console.log('GeneratePopover options:', options);
    onClick(options);
  };

  return (
    <Box>
      <Popover>
        <PopoverTrigger><span>
          <Tooltip hasArrow openDelay={250} label="Generate...">
            <IconButton
              isLoading={isGenerating}
              icon={<AiOutlinePlayCircle />}
              colorScheme="green"
              variant={'ghost'}
            />
          </Tooltip>
        </span></PopoverTrigger>
        <PopoverContent>
          <PopoverHeader fontWeight="semibold">Generate</PopoverHeader>
          <PopoverArrow />
          <PopoverBody maxHeight={'15rem'} overflowY={'auto'}>
            <Flex w={'full'} direction={'column'} gap={2}>
              {/* Private toggle and Priority */}
              <ButtonGroup w={'full'}>
                <Tooltip
                  hasArrow
                  label={isPrivate ? 'Parameters are hidden' : 'Parameters are visible'}
                >
                  <IconButton
                    style={{ margin: 0 }}
                    icon={isPrivate ? <BiHide /> : <BiShow />}
                    size={'sm'}
                    variant={'ghost'}
                    colorScheme={'blue'}
                    onClick={(e) => {
                      localStorage.setItem("private", !isPrivate)
                      setIsPrivate(!isPrivate);
                    }}
                  />
                </Tooltip>
                <Tooltip
                  hasArrow
                  label={`Generate 1 image`}
                >
                  <Button
                    w={'full'}
                    size={'sm'}
                    leftIcon={<VscDebugStart />}
                    colorScheme="green"
                    variant={'solid'}
                    isLoading={isGenerating}
                    onClick={() => handleGenerate(false)}
                  >
                    Generate
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
