import { useCallback, useState, useRef, useEffect } from 'react';
import { useNodesState, useEdgesState } from 'reactflow';
import { Box, Icon, Text, Flex, Tooltip, HStack, useToast, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { CgImage, CgAttribution } from 'react-icons/cg'
import { useParams, useNavigate } from 'react-router-dom';
import { FiPackage } from 'react-icons/fi';
import { LoadPipelinePopover } from './utils/LoadPipelinePopover';

// Local imports
import FlowView from './FlowView';
import NodeView from './NodeView/NodeView';
import GalleryView from './GalleryView';
import { NodeDropdown } from './utils/NodeDropdown';
import { flowToPipeline, extractJobParams, generateId } from './utils/topipeline';
import { pipelineToFlow, clearPositionCache, updateNodePosition } from './utils/frompipeline';
import { GeneratePopover } from './utils/GeneratePopover';
import { defaultPipelines } from './utils/defaultPipelines';
import { useAuth } from '../../contexts/AuthContext';
import { getEnvVariable } from '../../utils/env';

// API utilities
const lookupJob = async (apiUrl, authToken, id) => {
  if (!apiUrl) {
    console.error("API URL not configured");
    return null;
  }

  try {
    const jobUrl = `${apiUrl}/v3/anyjob/${id}`;

    const headers = {
      "Content-Type": "application/json",
      "Authorization": authToken ? `Bearer ${authToken}` : undefined
    };

    // Use AbortController to timeout the request if it takes too long
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000);

    const response = await fetch(jobUrl, {
      headers,
      signal: controller.signal
    });
    clearTimeout(timeoutId);

    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
      return null;
    }

    const text = await response.text();
    try {
      return JSON.parse(text);
    } catch (e) {
      console.error("Failed to parse job response:", e);
      console.debug("Raw response:", text.substring(0, 200) + "...");
      return null;
    }
  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('Job lookup timed out, continuing with editor load');
      return null;
    }
    console.error("Error fetching job:", error);
    return null;
  }
};

const fetchObjectInfo = async (apiUrl, authToken) => {
  if (!apiUrl || !authToken) {
    console.error("Cannot fetch object info - missing API URL or token");
    return null;
  }

  try {
    const infoUrl = `${apiUrl}/object_info`;
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${authToken}`
    };

    const response = await fetch(infoUrl, { headers });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching object info:", error);
    return null;
  }
}

const standardizePipeline = (pipelineData, source = 'default') => {
  if (!pipelineData) return null;

  // Track both numeric IDs and IdNumbers to avoid conflicts
  const usedIds = new Set();
  const usedIdNumbers = new Set();
  let maxId = 0;

  // First pass: collect existing IDs and IdNumbers
  Object.entries(pipelineData).forEach(([id, node]) => {
    if (!isNaN(id)) {
      usedIds.add(parseInt(id));
      maxId = Math.max(maxId, parseInt(id));
    }
    if (typeof node.IdNumber === 'number') {
      usedIdNumbers.add(node.IdNumber);
      maxId = Math.max(maxId, node.IdNumber);
    }
  });

  // Helper to get next available number
  const getNextAvailable = (set, start) => {
    let next = start;
    while (set.has(next)) next++;
    return next;
  };

  // Second pass: create standardized pipeline
  return Object.entries(pipelineData).reduce((acc, [id, node]) => {
    // Determine node ID (preserve API numeric IDs or named IDs)
    const nodeId = source === 'api' && !isNaN(id) ? parseInt(id) : id;

    // Calculate IdNumber with improved logic
    let idNumber;
    if (typeof node.IdNumber === 'number' && !usedIdNumbers.has(node.IdNumber)) {
      // Use existing IdNumber if valid and unique
      idNumber = node.IdNumber;
    } else if (!isNaN(id) && !usedIdNumbers.has(parseInt(id))) {
      // Use numeric ID as IdNumber if available
      idNumber = parseInt(id);
    } else {
      // Generate new unique IdNumber
      idNumber = getNextAvailable(usedIdNumbers, maxId + 1);
    }
    usedIdNumbers.add(idNumber);

    // Create standardized node
    acc[nodeId] = {
      ...node,
      class_type: node.class_type,
      IdNumber: idNumber,
      inputs: { ...node.inputs } || {},
      position: node.position || null
    };
    return acc;
  }, {});
};

export function NewEditor() {
  const params = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const accordionRefs = useRef({});
  const { isAuthenticated, token } = useAuth();
  const { user } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [scrollTo, setScrollTo] = useState(null);
  const [selectedPipeline, setSelectedPipeline] = useState('default');
  const [data, setData] = useState({
    pipeline: {},
    assets: {},
    flow: { nodes: [], edges: [] },
  });
  const [objectInfo, setObjectInfo] = useState(null);
  const [nodeDefinitions, setNodeDefinitions] = useState({});
  const [showNodePicker, setShowNodePicker] = useState(false);
  const [nodeCounter, setNodeCounter] = useState(1);
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNodes, setIsLoadingNodes] = useState(true);
  const [tabIndex, setTabIndex] = useState(() => {
    // Initialize from localStorage or default to GalleryView (1)
    return parseInt(localStorage.getItem('editorTabIndex') || '1');
  });
  const [showPipelineSelect, setShowPipelineSelect] = useState(false);
  const [zoomToNodeId, setZoomToNodeId] = useState(null);
  const REACT_APP_api_url = getEnvVariable('REACT_APP_api_url', process.env.REACT_APP_api_url);
  const [nodePositions, setNodePositions] = useState({});

  // Initialize tab selection when component mounts
  useEffect(() => {
    const savedIndex = localStorage.getItem('editorTabIndex');
    if (savedIndex) {
      setTabIndex(parseInt(savedIndex));
    }
  }, []);

  // Save tab selection to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('editorTabIndex', tabIndex.toString());
  }, [tabIndex]);

  // Use ref for token to prevent unnecessary effect triggers
  const tokenRef = useRef(token);
  useEffect(() => {
    tokenRef.current = token;
  }, [token]);
  const loadingRef = useRef(false);

  useEffect(() => {
    if (scrollTo) {
      const nodeElement = accordionRefs.current[scrollTo];
      if (nodeElement) {
        nodeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [scrollTo]);

  const handleGenerate = async (options) => {
    try {
      setIsGenerating(true);
      const pipelineData = flowToPipeline(nodes, edges);

      // Create multiple jobs if batch size > 1
      let size = options.batchSize
      size = 1  // For now, we will only allow one job at a time
      const jobs = Array(size).fill().map(() => {
        // Generate the job ID first
        const jobId = generateId();
        console.log('Generated job ID:', jobId); // Debug log

        const jobParams = extractJobParams(pipelineData.pipeline, {
          ...options,
          author: user?.sub,
          id: jobId // Pass the generated ID
        }, objectInfo);

        console.log('Generated job data:', JSON.stringify(jobParams, null, 2));
        return jobParams;
      });

      // Submit jobs
      console.log(`Submitting ${jobs.length} jobs to ${REACT_APP_api_url}/v3/job`);
      const results = await Promise.all(jobs.map(job => {
        let assets = {

        }
        const pipeline = data.pipeline;
        const body = JSON.stringify({ ...job, pipeline, assets });
        return fetch(`${REACT_APP_api_url}/v3/job`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
          },
          body
        })
      }
      ));

      // Navigate to first job if successful
      if (results[0].ok) {
        const firstJobResponse = await results[0].json();
        // We will go with _id if available, otherwise use the first job ID from client-side
        // navigate(`/neweditor/${firstJobResponse._id || jobs[0]._id}`);
      } else {
        // Log error response
        const errorText = await results[0].text();
        throw new Error(`API Error: ${errorText}`);
      }

      toast({
        title: 'Jobs Created',
        description: `Successfully submitted ${jobs.length} generation jobs`,
        status: 'success',
        duration: 5000,
        isClosable: true
      });

    } catch (error) {
      console.error('Error generating jobs:', error);
      toast({
        title: 'Error',
        description: error.message || 'Failed to create generation jobs',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleAddNode = (nodeType) => {
    console.log('Adding node of type:', nodeType);
    handleupdatedata(data.pipeline, objectInfo, 'add_node', { nodeType });
  };

  const handleDeleteNode = (nodeId) => {
    handleupdatedata(data.pipeline, objectInfo, 'delete_node', { nodeId });
  };

  const handleEdgeAdd = (params) => {
    handleupdatedata(data.pipeline, objectInfo, 'add_edge', params);
  };

  const handleupdatedata = useCallback(
    (pipelineData, objectInfo, action = 'update', params = {}) => {
      if (!pipelineData || !objectInfo) {
        console.warn('handleupdatedata: Missing required data', {
          hasPipeline: !!pipelineData,
          hasObjectInfo: !!objectInfo,
        });
        return;
      }

      try {
        let updatedPipeline = { ...pipelineData };
        console.debug(`handleupdatedata: Processing ${action}`, params);

        switch (action) {
          case 'add_node':
            const { nodeType, position } = params;
            const baseId = nodeType.toLowerCase().replace(/[^a-z0-9]/g, '');
            let nodeId = baseId;
            let counter = 1;

            // Find highest existing IdNumber
            const maxIdNumber = Math.max(
              0,
              ...Object.values(updatedPipeline).map((n) => n.IdNumber || 0)
            );

            // Ensure unique ID
            while (updatedPipeline[nodeId]) {
              nodeId = `${baseId}_${counter}`;
              counter++;
            }

            // Create node with position and sequential IdNumber
            updatedPipeline[nodeId] = {
              class_type: nodeType,
              inputs: {},
              IdNumber: maxIdNumber + 1,
              position:
                position ||
                calculateNewNodePosition(Object.values(updatedPipeline)),
            };

            // Initialize inputs
            if (objectInfo[nodeType]?.input) {
              const nodeInfo = objectInfo[nodeType];
              ['required', 'optional'].forEach((category) => {
                if (nodeInfo.input[category]) {
                  Object.entries(nodeInfo.input[category]).forEach(
                    ([key, value]) => {
                      // Initialize with default value if available
                      updatedPipeline[nodeId].inputs[key] =
                        value?.default ?? null;
                    }
                  );
                }
              });
            }
            break;

          case 'add_edge':
            const { source, sourceHandle, target, targetHandle } = params;
            console.log('Adding edge:', {
              source,
              sourceHandle,
              target,
              targetHandle,
            });

            if (updatedPipeline[target]) {
              if (targetHandle in updatedPipeline[target].inputs) {
                updatedPipeline[target].inputs[targetHandle] = [
                  source,
                  sourceHandle || '0',
                ];
                console.log('Updated pipeline after edge:', updatedPipeline);
              } else {
                console.warn(
                  `Target input "${targetHandle}" not found in node "${target}"`
                );
              }
            } else {
              console.warn(`Target node "${target}" not found`);
            }
            break;

          case 'delete_edge':
            const { edge } = params;
            if (updatedPipeline[edge.target]?.inputs) {
              Object.entries(updatedPipeline[edge.target].inputs).forEach(
                ([key, value]) => {
                  if (
                    Array.isArray(value) &&
                    value[0] === edge.source &&
                    value[1] === edge.sourceHandle
                  ) {
                    updatedPipeline[edge.target].inputs[key] = null;
                  }
                }
              );
            }
            break;

          case 'load_pipeline':
            // Standardize pipeline during load
            updatedPipeline = standardizePipeline(pipelineData, params.source);
            // Add positions if not present
            Object.entries(updatedPipeline).forEach(([id, node]) => {
              if (!node.position) {
                node.position = calculateNewNodePosition(
                  Object.values(updatedPipeline)
                );
              }
            });
            break;

          case 'update_node':
            const { nodeId: updateId, data: nodeData } = params;
            if (updatedPipeline[updateId]) {
              updatedPipeline[updateId] = {
                ...updatedPipeline[updateId],
                ...nodeData,
              };
            }
            break;

          case 'delete_node':
            const { nodeId: deleteId } = params;
            // Remove node
            delete updatedPipeline[deleteId];
            // Clean up any edges connected to this node
            Object.values(updatedPipeline).forEach((node) => {
              if (node.inputs) {
                Object.entries(node.inputs).forEach(([key, value]) => {
                  if (Array.isArray(value) && value[0] === deleteId) {
                    node.inputs[key] = null;
                  }
                });
              }
            });
            break;

          default:
            break;
        }

        // Validate pipeline before conversion
        if (!validatePipeline(updatedPipeline)) {
          console.error('Invalid pipeline structure detected');
          return;
        }

        const { nodes: newNodes, edges: newEdges } = pipelineToFlow(
          updatedPipeline,
          objectInfo
        );

        setData({
          pipeline: updatedPipeline,
          flow: { nodes: newNodes, edges: newEdges },
        });
      } catch (error) {
        console.error('Error in handleupdatedata:', error);
        toast({
          title: 'Error',
          description: 'Failed to update pipeline',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [toast]
  );

  useEffect(() => {
    const InitloadData = async () => {
      if (!token || loadingRef.current) return;

      loadingRef.current = true;

      try {
        // Start both fetches in parallel
        const [objectInfoPromise, jobDataPromise] = [
          fetchObjectInfo(REACT_APP_api_url, tokenRef.current),
          params.id
            ? lookupJob(REACT_APP_api_url, tokenRef.current, params.id)
            : Promise.resolve(null),
        ];

        // Use Promise.allSettled to handle partial failures
        const [objectInfoResult, jobDataResult] = await Promise.allSettled([
          objectInfoPromise,
          jobDataPromise,
        ]);

        // Handle object info result
        if (objectInfoResult.status === 'rejected') {
          throw new Error('Failed to fetch ObjectInfo');
        }
        const objectInfoData = objectInfoResult.value;
        if (!objectInfoData) {
          throw new Error('No ObjectInfo data received');
        }

        setObjectInfo(objectInfoData);

        ///////////////////////onjectInfo data definitions ////////////////////////////////////
        const normalizedObjectInfo = {};
        Object.entries(objectInfoData).forEach(([type, info]) => {
          if (!type || !info) return;
          normalizedObjectInfo[type] = {
            ...info,
            originalType: type,
            display_name: info.display_name || type,
            description: info.description || '',
            category: info.category || 'unknown',
            output_node: !!info.output_node,
            input: {
              required: info.input?.required || {},
              optional: info.input?.optional || {},
              hidden: info.input?.hidden || {},
            },
          };
        });
        setNodeDefinitions(normalizedObjectInfo);
        console.log('ObjectInfo data:', normalizedObjectInfo);
        ///////////////////////onjectInfo data definitions ////////////////////////////////////

        if (jobDataResult.status === 'fulfilled' && jobDataResult.value) {
          const jobData = jobDataResult.value;
          if (jobData.pipeline) {
            handleupdatedata(
              jobData.pipeline,
              objectInfoData,
              'load_pipeline',
              { source: 'api' }
            );
          }
        } else {
          // Load default pipeline
          const defaultPipeline = defaultPipelines.default.pipeline;
          handleupdatedata(defaultPipeline, objectInfoData, 'load_pipeline', {
            source: 'default',
          });
        }
      } catch (error) {
        console.error('Error in loadEditorData:', error);
        toast({
          title: 'Error',
          description: 'Failed to load editor data. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
        setFetching(false);
        setIsLoaded(true);
        setIsLoadingNodes(false);
        loadingRef.current = false;
      }
    };
    InitloadData();
  }, [token, params.id, REACT_APP_api_url, toast, handleupdatedata]);

  // Add pipeline validation helper
  const validatePipeline = (pipeline) => {
    if (!pipeline || typeof pipeline !== 'object') return false;

    return Object.entries(pipeline).every(([id, node]) => {
      return (
        node &&
        typeof node === 'object' &&
        typeof node.class_type === 'string' &&
        typeof node.IdNumber === 'number' &&
        typeof node.inputs === 'object'
      );
    });
  };

  // Helper function to calculate new node position
  const calculateNewNodePosition = (existingNodes) => {
    if (!existingNodes.length) {
      return { x: 100, y: 100 };
    }

    // Find rightmost node
    const rightmostNode = existingNodes.reduce((rightmost, node) => {
      if (
        !rightmost ||
        (node.position?.x || 0) > (rightmost.position?.x || 0)
      ) {
        return node;
      }
      return rightmost;
    }, null);

    return {
      x: (rightmostNode.position?.x || 0) + 300,
      y: rightmostNode.position?.y || 0,
    };
  };

  const handleUpdateNodeId = useCallback(
    (oldId, newId) => {
      console.log('Updating node ID:', { oldId, newId });

      if (!data.pipeline[oldId]) {
        console.warn('Old node ID not found:', oldId);
        return;
      }

      try {
        // Store current node position and data
        const currentNode = data.pipeline[oldId];
        const currentPosition = data.flow.nodes.find(
          (n) => n.id === oldId
        )?.position;

        // Create new pipeline with updated node ID
        const updatedPipeline = { ...data.pipeline };
        const nodeData = {
          ...updatedPipeline[oldId],
          position: currentPosition, // Preserve position in node data
        };
        delete updatedPipeline[oldId];
        updatedPipeline[newId] = nodeData;

        // Update references in other nodes' inputs
        Object.values(updatedPipeline).forEach((node) => {
          if (node.inputs) {
            Object.entries(node.inputs).forEach(([key, value]) => {
              if (Array.isArray(value) && value[0] === oldId) {
                node.inputs[key] = [newId, value[1]];
              }
            });
          }
        });

        // Update position cache with new node ID
        if (currentPosition) {
          updateNodePosition(newId, currentPosition);
        }

        // Convert updated pipeline to flow format
        const updatedFlow = pipelineToFlow(updatedPipeline, objectInfo, {
          pipelineType: selectedPipeline,
          forceLayout: false,
        });

        setData((prevData) => ({
          ...prevData,
          pipeline: updatedPipeline,
          flow: updatedFlow,
        }));

        console.log('Node ID update successful:', {
          updatedPipeline,
          updatedFlow,
        });
      } catch (error) {
        console.error('Error updating node ID:', error);
        toast({
          title: 'Error',
          description: 'Failed to update node ID',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    },
    [data, objectInfo, selectedPipeline, toast]
  );

  const loadPipeline = useCallback(
    async (options = {}) => {
      if (!token || loadingRef.current) return;

      console.log('Starting pipeline load...', options);
      setIsLoading(true);
      setFetching(true);
      loadingRef.current = true;
      setIsLoadingNodes(true);

      try {
        // Only clear cache if not preserving layout
        if (!options.preserveLayout) {
          clearPositionCache();
        }

        if (!objectInfo) {
          throw new Error('Node definitions not loaded yet');
        }

        // Use provided pipelineData or fall back to default
        let pipelineData =
          options.pipelineData ||
          (selectedPipeline
            ? defaultPipelines[selectedPipeline]?.pipeline
            : defaultPipelines.default.pipeline);

        if (!pipelineData) {
          throw new Error('No pipeline data found');
        }

        // Update pipeline with layout options
        handleupdatedata(pipelineData, objectInfo, 'load_pipeline', {
          source: 'default',
          positions: nodePositions,
          preserveLayout: options.preserveLayout,
        });
      } catch (error) {
        console.error('Error in loadPipeline:', error);
        toast({
          title: 'Error',
          description: error.message || 'Failed to load pipeline',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
        setFetching(false);
        loadingRef.current = false;
        setIsLoadingNodes(false);
      }
    },
    [
      objectInfo,
      nodePositions,
      handleupdatedata,
      selectedPipeline,
      token,
      toast,
    ]
  );

  const handleZoomToNode = useCallback((nodeId) => {
    if (!nodeId) return;
    // Switch to FlowView tab and set zoom target
    setTabIndex(0);
    setZoomToNodeId(nodeId);
    // Reset zoom target after animation completes
    setTimeout(() => {
      setZoomToNodeId(null);
    }, 850); // Match duration with FlowView's zoom animation
  }, []);

  const handleNodePositionChange = useCallback((nodeId, position) => {
    setData((prevData) => {
      const updatedPipeline = { ...prevData.pipeline };
      if (updatedPipeline[nodeId]) {
        // Only update if position actually changed
        if (
          !updatedPipeline[nodeId].position ||
          updatedPipeline[nodeId].position.x !== position.x ||
          updatedPipeline[nodeId].position.y !== position.y
        ) {
          updatedPipeline[nodeId] = {
            ...updatedPipeline[nodeId],
            position,
          };

          return {
            ...prevData,
            pipeline: updatedPipeline,
          };
        }
      }
      return prevData;
    });
  }, []);

  const onNodeDragStop = useCallback((event, node) => {
    // Update position cache when node is dragged
    updateNodePosition(node.id, node.position);
  }, []);

  return (
    <Box direction="column" w={'full'} h={'calc(100dvh - 370px)'}>
      <HStack
        display={'flex'}
        width={'full'}
        h={'70px'}
        verticalAlign={'center'}
        spacing={4}
      >
        <Box flex={1}>
          <Text w={'100px'}>Flow Editor</Text>
        </Box>
        <Box flex={1}>
          <GeneratePopover
            isGenerating={isGenerating}
            data={data}
            onClick={handleGenerate}
            parent_id={params?.id}
          />
        </Box>
        <Box flex={'100%'}></Box>
        <Box flex={1} justify-self="end">
          <NodeDropdown
            obj={objectInfo}
            onClick={handleAddNode}
            asPopover={true}
          />
        </Box>
        <Box flex={1} justify-self="end">
          <LoadPipelinePopover
            isLoading={isLoadingNodes}
            onLoad={(pipelineData) => {
              console.log(
                'LoadPipelinePopover onLoad called with:',
                pipelineData
              );
              if (pipelineData) {
                // Clear position cache to force new layout
                clearPositionCache();
                loadPipeline({
                  pipelineData,
                  preserveLayout: false, // Force new layout
                });
              }
            }}
            isDisabled={!objectInfo}
            selectedPipeline={selectedPipeline}
            onPipelineSelect={(value) => {
              console.log(
                'LoadPipelinePopover onPipelineSelect called with:',
                value
              );
              if (value) {
                setSelectedPipeline(value);
              }
            }}
          />
        </Box>
      </HStack>
      <Flex w={'full'} direction={{ base: 'column', lg: 'row' }}>
        <Box overflowY={'auto'} display={'block'} h={'100%'} w={'650px'}>
          <Box display={'block'} h={'calc(100dvh - 240px)'} w={'full'} p={2}>
            <NodeView
              objectInfo={objectInfo}
              data={data}
              nodeDefinitions={nodeDefinitions}
              onUpdateNode={(nodeId, updatedNode) => {
                setData({
                  ...data,
                  pipeline: {
                    ...data.pipeline,
                    [nodeId]: updatedNode,
                  },
                });
              }}
              onDeleteNode={handleDeleteNode}
              onUpdateNodeId={handleUpdateNodeId}
              onZoomToNode={handleZoomToNode}
              isLoading={isLoading || fetching}
            />
          </Box>
        </Box>
        <Box display={'block'} h={'calc(100dvh - 240px)'} w={'full'}>
          <Tabs
            w={'100%'}
            orientation="vertical"
            defaultIndex={tabIndex}
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
            isLazy={false} // Disable lazy loading to force immediate render
          >
            <TabList>
              <Tab>
                <Tooltip placement="right" label="Flow Editor" hasArrow>
                  <Icon boxSize={8} children={<CgAttribution />} />
                </Tooltip>
              </Tab>
              <Tab>
                <Tooltip placement="right" label="Gallery View" hasArrow>
                  <Icon boxSize={8} children={<CgImage />} />
                </Tooltip>
              </Tab>
              <Tab>
                <Tooltip placement="right" label="Assets" hasArrow>
                  <Icon boxSize={8} children={<FiPackage />} />
                </Tooltip>
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box display={'block'} h={'calc(100dvh - 240px)'} w={'full'}>
                  <FlowView
                    data={data}
                    objectInfo={objectInfo}
                    onDeleteNode={handleDeleteNode}
                    onZoomToNode={zoomToNodeId}
                    onUpdate={(updatedData) => {
                      setData((prevData) => ({
                        ...prevData,
                        ...updatedData,
                      }));
                    }}
                    onNodePositionChange={handleNodePositionChange}
                    onEdgeAdd={handleEdgeAdd}
                    initialPositions={nodePositions}
                    onNodeDragStop={onNodeDragStop}
                  />
                </Box>
              </TabPanel>
              <TabPanel>
                <GalleryView
                  objectInfo={objectInfo}
                  data={data}
                  onCreate={(piece) => {
                    console.log('Loading pipeline from gallery piece:', piece);
                    if (!piece || !piece.pipeline) {
                      console.warn('No pipeline data in piece');
                      return;
                    }

                    // Switch to Flow Editor tab after loading
                    setTabIndex(0);

                    // Clear position cache before loading new pipeline
                    clearPositionCache();

                    // Load the pipeline with layout options
                    loadPipeline({
                      pipelineData: piece.pipeline,
                      preserveLayout: false,
                      pipelineType: 'gallery',
                    });
                  }}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </Box>
  );
};

export default NewEditor;
