import { useState, useCallback, useRef } from 'react';
import {
    Box,
    Heading,
    Tooltip,
    Flex,
    IconButton,
    Spacer,
    Collapse,
    Text,
    Badge,
    Input,
    useToast
} from '@chakra-ui/react';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';
import { EditIcon } from '@chakra-ui/icons';

/**
 * PropBox component wraps field groups with consistent styling and behavior
 * Matches Workshop's PropBox implementation for compatibility
 */
export const PropBox = ({
    children,
    label,
    value,
    onChange,
    IdNumber,
    extras = [],
    type,
    collapsed: defaultCollapsed = false,
    collapsible = false,
    onExpand,
    onCollapse,
    fetching = false,
    node,
    id,
    onZoomToNode,
    onUpdateNodeId
}) => {
    const [collapsed, setCollapsed] = useState(defaultCollapsed);
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(node?.id || id);
    const toast = useToast();
    const lastZoomTime = useRef(0);

    const handleToggle = useCallback(() => {
        if (!collapsible) return;
        const newState = !collapsed;
        setCollapsed(newState);
        if (newState) {
            onCollapse && onCollapse();
        } else {
            onExpand && onExpand();
        }
    }, [collapsible, collapsed, onExpand, onCollapse]);

    const handleEditClick = (e) => {
        e.stopPropagation();
        setEditValue(node?.id || id); // Initialize with current ID
        setIsEditing(true);
    };

    const handleEditSubmit = () => {
        if (!editValue || editValue.trim() === '') {
            toast({
                title: "Invalid ID",
                description: "Node ID cannot be empty",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            setEditValue(node?.id || id); // Reset to current ID
            return;
        }

        const newId = editValue.trim();
        if (newId === (node?.id || id)) {
            setIsEditing(false);
            return;
        }

        // Submit change while preserving IdNumber and other properties
        const oldId = node?.id || id;
        onUpdateNodeId && onUpdateNodeId(oldId, newId, node?.IdNumber);
        setIsEditing(false);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleEditSubmit();
        } else if (e.key === 'Escape') {
            setIsEditing(false);
            setEditValue(node?.id || id);
        }
    };

    const handleZoomClick = useCallback((e) => {
        e.stopPropagation();
        
        const now = Date.now();
        if (now - lastZoomTime.current < 1000) {
            return;
        }
        
        lastZoomTime.current = now;
        onZoomToNode && onZoomToNode(node?.IdNumber || IdNumber);
    }, [onZoomToNode, node?.IdNumber, IdNumber]);

    return (
        <Box
            bg="gray.800"
            borderWidth="1px"
            borderColor="gray.700"
            borderRadius="md"
            overflow="hidden"
        >
            <Flex
                p={2}
                userSelect="none"
                alignItems="center"
                bg="gray.900"
            >
                {collapsible && (
                    <Box
                        as="span"
                        mr={2}
                        transition="transform 0.2s"
                        transform={collapsed ? "rotate(-90deg)" : "rotate(0deg)"}
                        display="flex"
                        alignItems="center"
                        onClick={handleToggle}
                        cursor={collapsible ? "pointer" : "default"}
                    >
                        <BsChevronDown />
                    </Box>
                )}
                <Heading size="sm" flex={1} display="flex" alignItems="center" p={2}>
                    {node?.IdNumber && (
                        <Badge 
                            colorScheme="blue" 
                            cursor="pointer"
                            onClick={handleZoomClick}
                            _hover={{ bg: 'blue.600' }}
                            p={1}
                            mr={2}
                        >
                            #{node?.IdNumber || IdNumber}
                        </Badge>
                    )}
                    {isEditing ? (
                        <Input
                            size="sm"
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            onBlur={handleEditSubmit}
                            onKeyDown={handleKeyPress}
                            width="auto"
                            autoFocus
                            onClick={(e) => e.stopPropagation()}
                            pl={2}
                        />
                    ) : (
                        <>
                            <Tooltip placement="top-start" label={node?.class_type || label} hasArrow>
                                <Text 
                                    overflow="hidden" 
                                    textOverflow="ellipsis" 
                                    whiteSpace="nowrap"
                                    maxW="200px"
                                    fontSize="sm"
                                    fontWeight="semibold"
                                >
                                    {node?.id || id}
                                </Text>
                            </Tooltip>
                            <IconButton
                                icon={<EditIcon />}
                                size="xs"
                                variant="ghost"
                                ml={2}
                                onClick={handleEditClick}
                                aria-label="Edit node ID"
                            />
                        </>
                    )}
                </Heading>
                <Spacer />
                <Flex gap={1}>
                    {extras.map((icon, index) => (
                        <Tooltip key={index} hasArrow label={icon.label}>
                            <IconButton
                                icon={icon.icon}
                                variant="ghost"
                                size="sm"
                                isLoading={fetching}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    icon.onClick(e);
                                }}
                            />
                        </Tooltip>
                    ))}
                </Flex>
            </Flex>
            <Collapse in={!collapsed} animateOpacity>
                <Box 
                    p={2}
                    borderTop={!collapsed ? "1px solid" : "none"}
                    borderTopColor="gray.700"
                >
                    {children}
                </Box>
            </Collapse>
        </Box>
    );
};
