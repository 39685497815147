import React from 'react';
import { Avatar, Box, Flex, HStack, IconButton, Button, Wrap, WrapItem, Text, Tooltip, useToast } from '@chakra-ui/react';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { FaTrash, FaTrashRestore } from 'react-icons/fa';
import { BiLinkExternal } from 'react-icons/bi'
import { FaPaintBrush } from 'react-icons/fa'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from 'contexts/AuthContext';
import { AiOutlineShareAlt, AiOutlineSave } from 'react-icons/ai';
import { RiGitRepositoryPrivateLine } from 'react-icons/ri';
import { getEnvVariable } from 'utils/env';
import { formatDistanceToNow } from 'date-fns'
import { TfiImport } from "react-icons/tfi";
import { useUser } from 'contexts/UserContext';

export function GalleryOverlay({
  onClick = () => console.warn('No onClick defined'),
  onCreate = () => console.warn('No onCreate defined'),
  onMoved = () => console.warn('No onMoved defined'),
  mode,
  piece,
  isInterested,
  source,
  openLightbox
}) {
  const { refresh } = useUser();
  const { isAuthenticated, token, user, permissions } = useAuth();
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();
  const [isPinned, setIsPinned] = useState(piece.pinned ? true : false);
  const [pinLoading, setIsPinLoading] = useState(false);
  const [delta, setDelta] = useState(0);
  const [isDeleted, setIsDeleted] = useState(piece.deleted ? true : false);
  const [isMoving, setIsMoving] = useState(false);
  const toast = useToast();

  const REACT_APP_api_url = getEnvVariable(
    'REACT_APP_api_url',
    process.env.REACT_APP_api_url
  );
  // console.log(piece)

  let duration = 0.2;
  let interestedStyle = {
    cursor: 'pointer',
    visibility: 'visible',
    opacity: 1,
    transition: `opacity ${duration}s linear`,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  };
  let uninterestedStyle = {
    visibility: 'hidden',
    opacity: 0.4,
    transition: `visibility 0s ${duration}s, opacity ${duration}s linear, backdrop-filter ${duration}s`,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  };
  let overlayStyle, maskStyle;
  if (isInterested) {
    overlayStyle = interestedStyle;
  } else {
    overlayStyle = uninterestedStyle;
  }
  if (isDeleted && !isInterested) {
    maskStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: '5px solid rgba(200, 0, 0, 0.8)',
      // transform: 'scale(1.1)', zIndex: 2,
      // transition: "0.2s ease-in-out"
      // backdropFilter : "blur(10px)",
      // backgroundColor : "rgba(0,0,0,0.8)"
    };
  } else {
    maskStyle = {};
  }
  if (isMoving) {
    overlayStyle.backdropFilter = 'blur(10px)';
    overlayStyle.backgroundColor = 'rgba(0,0,0,0.4)';
  }

  useEffect(() => {
    setIsPinned(piece.pinned ? true : false);
    setIsDeleted(piece.deleted ? true : false);
  }, [piece]);
  useEffect(() => {}, [isPinned, isDeleted]);

  let timeRelative = 'Long, Long Ago...';
  for (let field of ['timestamp_completed', 'time_completed']) {
    if (piece[field] && piece[field].$date) {
      const timestamp = new Date(piece[field].$date);
      timeRelative = formatDistanceToNow(timestamp, { addSuffix: true });
    }
  }

  return (
    <>
      <Box style={maskStyle}>
        <Box
          style={overlayStyle}
          onClick={() => {
            openLightbox(piece);
          }}
        >
          <Box pos="absolute" bottom="0" m={5} p={2}>
            {piece.userdets && (
              <div
                style={(() => {
                  let bgColor = 'rgba(0,0,0,0.4)';
                  if (user && piece.author === user.sub) {
                    if (piece.private) {
                      bgColor = 'rgba(200,0,0,0.4)';
                    } else {
                      bgColor = 'rgba(0,170,50,0.4)';
                    }
                  }
                  let authorStyle = {
                    backgroundColor: bgColor,
                    width: '100%',
                    height: '100%',
                    padding: '5px',
                    borderRadius: '10px',
                    backdropFilter: 'blur(10px)',
                    boxShadow: '0px 0px 10px 10px rgba(0,0,0,0.4)',
                  };
                  return authorStyle;
                })()}
              >
                <HStack>
                  <Box
                    onClick={(e) => {
                      navigate(`/gallery/${piece.author}/1`);
                      e.stopPropagation();
                    }}
                  >
                    <Wrap>
                      <WrapItem display="flex" alignItems="center">
                        {piece.userdets && (
                          <>
                            <Avatar
                              size={'sm'}
                              name={
                                piece.userdets.display_name
                                  ? piece.userdets.display_name
                                  : piece.userdets.nickname
                              }
                              src={
                                piece.userdets.picture
                                  ? piece.userdets.picture
                                  : piece.userdets.avatar
                              }
                            ></Avatar>
                            <Flex direction={'column'}>
                              <Text
                                ml={2}
                                style={{
                                  color: '#FFF',
                                  textShadow: '1px 1px 2px #2a2a2a',
                                  fontWeight: 'bold',
                                }}
                              >
                                {' '}
                                {piece.userdets.nickname
                                  ? piece.userdets.nickname
                                  : piece.userdets.display_name}
                              </Text>
                              <Text
                                ml={2}
                                style={{
                                  color: '#FFF',
                                  textShadow: '1px 1px 2px #2a2a2a',
                                }}
                              >
                                {' '}
                                {timeRelative}
                              </Text>
                            </Flex>
                          </>
                        )}
                      </WrapItem>
                    </Wrap>
                  </Box>
                </HStack>
              </div>
            )}
          </Box>
          <Flex
            direction={'column'}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              // zIndex : 1,
              opacity: 0.7,
              // color : "#FFF"
            }}
          >
            {mode === 'review' && (
              <>
                <Tooltip hasArrow label="Publish">
                  <IconButton
                    isRound
                    isLoading={isMoving}
                    colorScheme={'green'}
                    size="md"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsMoving(true);
                      let method = 'POST';
                      fetch(`${REACT_APP_api_url}/review/${piece._id}`, {
                        method: method,
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({}),
                      }).then((d) => {
                        onMoved();
                        setIsMoving(false);
                      });
                    }}
                    icon={<AiOutlineSave />}
                  />
                </Tooltip>
                {permissions.includes('supporter') && (
                  <Tooltip hasArrow label="Save Personal">
                    <IconButton
                      isRound
                      isLoading={isMoving}
                      colorScheme={'green'}
                      size="md"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsMoving(true);
                        let method = 'POST';
                        fetch(
                          `${REACT_APP_api_url}/review_personal/${piece._id}`,
                          {
                            method: method,
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `Bearer ${token}`,
                            },
                            body: JSON.stringify({}),
                          }
                        ).then((d) => {
                          onMoved();
                          setIsMoving(false);
                        });
                      }}
                      icon={<RiGitRepositoryPrivateLine />}
                    />
                  </Tooltip>
                )}
              </>
            )}
            {source === 'pieces' && mode !== 'review' && (
              <>
                <Tooltip hasArrow label="Like">
                  <Button
                    rounded={'full'}
                    // isRound
                    isLoading={pinLoading}
                    colorScheme={'pink'}
                    size="md"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!isAuthenticated) {
                        loginWithRedirect();
                      } else {
                        let method = 'POST';
                        if (isPinned) {
                          method = 'DELETE';
                        } else {
                          method = 'POST';
                        }
                        setIsPinLoading(true);
                        fetch(`${REACT_APP_api_url}/pin/${piece._id}`, {
                          method: method,
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                          },
                          body: JSON.stringify({}),
                        }).then((r) => {
                          if (isPinned) {
                            setIsPinned(false);
                            if (piece.pinned) {
                              setDelta(-1);
                            } else {
                              setDelta(0);
                            }
                          } else {
                            setIsPinned(true);
                            if (!piece.pinned) {
                              setDelta(+1);
                            } else {
                              setDelta(0);
                            }
                          }
                          setIsPinLoading(false);
                        });
                      }
                    }}
                    leftIcon={isPinned ? <AiFillHeart /> : <AiOutlineHeart />}
                  >
                    {(piece && piece.likes ? piece.likes : 0) + delta}
                  </Button>
                </Tooltip>
                {source === 'pieces' && (
                  <Tooltip hasArrow label="Share">
                    <IconButton
                      isRound
                      colorScheme={'purple'}
                      size="md"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(
                          `${window.location.origin}/piece/${piece._id}`
                        );
                        toast({
                          title: 'Share URL copied',
                          description:
                            'A share link has been copied to your clipboard.',
                          status: 'success',
                        });
                      }}
                      // ml={1}
                      // isDisabled
                      icon={<AiOutlineShareAlt />}
                    ></IconButton>
                  </Tooltip>
                )}
                {source === 'pieces' && (
                  <Tooltip hasArrow label="Open Details in new tab">
                    <IconButton
                      rounded={'full'}
                      colorScheme={'blue'}
                      size="md"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(`/piece/${piece._id}`, '_blank');
                      }}
                      icon={<BiLinkExternal />}
                    />
                  </Tooltip>
                )}
              </>
            )}
            <Tooltip hasArrow label="Import to Datasets">
              <IconButton
                isRound
                isLoading={isMoving}
                colorScheme={'purple'}
                size="md"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsMoving(true);
                  fetch(`${REACT_APP_api_url}/v3/rendertoimagehash`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ hash: piece._id }),
                  }).then((d) => {
                    setIsMoving(false);
                    toast({
                      title: `Imported to Datasets.`,
                    });
                    refresh();
                  });
                }}
                icon={<TfiImport />}
              />
            </Tooltip>
          </Flex>
          <Flex
            direction={'column'}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              // zIndex : 2
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {((user && user.sub && piece && piece.author === user.sub) ||
              permissions.includes('seeprompts')) &&
              !piece.orphaned &&
              !isDeleted && (
                <>
                  <Tooltip hasArrow label="Create">
                    <IconButton
                      colorScheme={'green'}
                      isRound
                      icon={<FaPaintBrush />}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (token) {
                          if (onCreate) {
                            onCreate(piece);
                          } else {
                            // setModalOpen(true)
                          }
                          navigate(`/neweditor/${piece._id}`);
                        } else {
                          loginWithRedirect(`/${piece._id}`);
                        }
                      }}
                    />
                  </Tooltip>
                </>
              )}
            {user &&
              (piece.author === user.sub || permissions.includes('admin')) && (
                <Tooltip hasArrow label={isDeleted ? 'Undelete' : 'Delete'}>
                  <IconButton
                    isRound
                    isLoading={isMoving}
                    colorScheme={'red'}
                    size="md"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsMoving(true);
                      if (!isAuthenticated) {
                        loginWithRedirect();
                      } else {
                        let method = 'POST';
                        let url = `${REACT_APP_api_url}/delete/${piece._id}`;
                        if (mode === 'uploads')
                          url = `${REACT_APP_api_url}/v3/deleteimagehash/${piece._id}`;
                        if (isDeleted) {
                          url = `${REACT_APP_api_url}/undelete/${piece._id}`;
                        }
                        fetch(url, {
                          method,
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                          },
                        }).then((d) => {
                          onMoved();
                          setIsMoving(false);
                        });
                      }
                    }}
                    icon={isDeleted ? <FaTrashRestore /> : <FaTrash />}
                  />
                </Tooltip>
              )}
          </Flex>
        </Box>
      </Box>
    </>
  );
}